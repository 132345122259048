import { Navigate, Outlet } from "react-router";

interface GuardProps {
  isRouteAccessible?: boolean;
  redirectRoute?: string;
}

export default function Guard({
  isRouteAccessible = false,
  redirectRoute = "/",
}: GuardProps) {
  return isRouteAccessible ? (
    <Outlet />
  ) : (
    <Navigate to={redirectRoute} replace />
  );
}