import { Box, DialogContent, Grid, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { User } from "../../../models/user";
import { logoutUser } from "../../../services/auth.service";
import { setUser } from "../../../store/user/actions";
import { Button } from "../../../ui/button";
import { Dialog } from "../../../ui/dialog";
import palette from "../../../ui/palette";

export default function LogoutConfirm({
  handleClose,
  open,
}: {
  handleClose: () => void;
  open: boolean;
}) {
  const dispatch = useDispatch();

  const logout = () => {
    logoutUser();
  };

  return (
    <Dialog
      size="lg"
      open={open}
      onClose={(ev, reason) => {
        if (reason && reason === "backdropClick") {
          return;
        }
        handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        sx={{
          "& .MuiDialogContent-root": {
            padding: 0,
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "2.5rem",
            lineHeight: 1.1,
            fontWeight: 500,
            mb: 3,
          }}
        >
          Log Out
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: 400 }}>
          Are you sure you want to log out?
        </Typography>
        <Grid container sx={{ mt: 5 }} columnSpacing={3}>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={handleClose}
              variant="outlined"
              sx={{
                py: 14 / 8,
                px: 20 / 8,
                border: `1px solid ${palette.dark.tertiary}`,
              }}
            >
              Return
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={logout}
              variant="contained"
              sx={{
                py: 14 / 8,
                px: 20 / 8,
              }}
            >
              Log out
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
