import { Box, DialogTitle, Link, Stack, Typography } from '@mui/material';
import { Dialog } from '../../../ui/dialog';
import { IReport } from '../../../models/report';
import { BlobProvider } from '@react-pdf/renderer';
import { PDFReport } from '../../pdf-report';

interface Props {
  application: IReport;
  onClose: VoidFunction;
}

export default function ViewReportDialog({ onClose, application }: Props) {
  return (
    <Dialog fullWidth open onClose={onClose} PaperProps={{ sx: { maxWidth: '472px' } }}>
      <DialogTitle>View Report</DialogTitle>
      <Box sx={{ px: 3, pb: 4 }}>
        <Typography variant="body1" sx={{ mb: 1.5 }}>
          Select which report you want to view
        </Typography>
        <Stack flexDirection="row" sx={{ px: 2 }}>
          <Stack sx={{ flex: 1 }} gap={1.5}>
            <Typography variant="subtitle1">Applicants</Typography>
            {application.applicants
              .filter((a: any) => a.role !== 'Guarantor' && a.role !== 'Non-Resident Co-sign' && a.role !== 'Family Member - Minor')
              .map(applicant => (
                <>
                  {applicant.income_history[0]?.report_link ? (
                    <>
                      <Stack gap={0.5} direction="row" alignItems="center" sx={{ mb: 1.5 }}>
                        <Typography
                          key={applicant._id}
                          variant="body1"
                          component="a"
                          color="primary"
                          sx={{ textDecoration: 'underline', fontWeight: 500, display: 'block', cursor: 'pointer' }}
                        >
                          <Link href={applicant.income_history[0].report_link} target="_blank">
                            {applicant.name}
                          </Link>
                        </Typography>
                        <Typography variant="overline" color="grey.400" sx={{ textTransform: 'initial' }}>
                          {'(Attempt 1)'}
                        </Typography>
                      </Stack>
                    </>
                  ) : applicant.require_income_verification && applicant.income_history.length ? (
                    <>
                      {applicant.income_history.map((ih, idx) => (
                        <Stack gap={0.5} direction="row" alignItems="center" sx={{ mb: 1.5 }}>
                          <Typography
                            key={applicant._id}
                            variant="body1"
                            component="a"
                            color="primary"
                            sx={{ textDecoration: 'underline', fontWeight: 500, display: 'block', cursor: 'pointer' }}
                          >
                            <BlobProvider document={<PDFReport applicant={applicant} application={application} idx={idx} />}>
                              {({ blob, url, loading, error }) =>
                                loading ? (
                                  'Loading document...'
                                ) : (
                                  <Link href={url!!} target="_blank">
                                    {applicant.name}
                                  </Link>
                                )
                              }
                            </BlobProvider>
                          </Typography>
                          <Typography variant="overline" color="grey.400" sx={{ textTransform: 'initial' }}>
                            {`(Attempt ${idx + 1})`}
                          </Typography>
                        </Stack>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ))}
          </Stack>
          <Stack sx={{ flex: 1 }} gap={1.5}>
            {application.applicants.filter((a: any) => a.role === 'Guarantor' || a.role === 'Non-Resident Co-sign').length ? (
              <>
                <Typography variant="subtitle1">Co-signer/Guarantor</Typography>
                {application.applicants
                  .filter((a: any) => a.role === 'Guarantor' || a.role === 'Non-Resident Co-sign')
                  .map(applicant => (
                    <>
                      {applicant.require_income_verification && applicant.income_history.length ? (
                        <>
                          {applicant.income_history.map((ih, idx) => (
                            <Stack gap={0.5} direction="row" alignItems="center" sx={{ mb: 1.5 }}>
                              <Typography
                                key={applicant._id}
                                variant="body1"
                                component="a"
                                color="primary"
                                sx={{ textDecoration: 'underline', fontWeight: 500, display: 'block', cursor: 'pointer' }}
                              >
                                <BlobProvider document={<PDFReport applicant={applicant} application={application} idx={idx} />}>
                                  {({ blob, url, loading, error }) =>
                                    loading ? (
                                      'Loading document...'
                                    ) : (
                                      <Link href={url!!} target="_blank">
                                        {applicant.name}
                                      </Link>
                                    )
                                  }
                                </BlobProvider>
                              </Typography>
                              <Typography variant="overline" color="grey.400" sx={{ textTransform: 'initial' }}>
                                {`(Attempt ${idx + 1})`}
                              </Typography>
                            </Stack>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
}
