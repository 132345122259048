import { Stack, Typography } from '@mui/material';
import { IApplicant, IReport } from '../../../models/report';
import moment from 'moment';
import DefaultVertificationMode from './DefaultVertificationMode';

export interface VerificationMethodProps {
  selectedApplicant: IApplicant;
  application: IReport;
  verificationMethodIndex: number;
}

export default function BankIncomeMode({ selectedApplicant, application, verificationMethodIndex }: VerificationMethodProps) {
  const selectedIncomeHistory = selectedApplicant.income_history[verificationMethodIndex] ?? selectedApplicant.income_history[0];
  return (
    <>
      <DefaultVertificationMode selectedApplicant={selectedApplicant} application={application} verificationMethodIndex={verificationMethodIndex} />
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Start Date of Income History</Typography>
        <Typography variant="body1">{moment(selectedIncomeHistory.start_date).format('MM/DD/YY')}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">End Date of Income History</Typography>
        <Typography variant="body1">{moment(selectedIncomeHistory.end_date).format('MM/DD/YY')}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Number of Income Sources</Typography>
        <Typography variant="body1">{selectedIncomeHistory.incomeSources.length}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Number of Income Transactions</Typography>
        <Typography variant="body1">{selectedIncomeHistory.income_transactions_count}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Overall Historic Earnings by Month</Typography>
        <Typography variant="body1">
          ${selectedApplicant.monthly_incomes?.length ? selectedApplicant.monthly_incomes[verificationMethodIndex] : selectedApplicant.monthly_income}
        </Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Recurring by Month</Typography>
        <Typography variant="body1">
          ${selectedApplicant.monthly_incomes?.length ? selectedApplicant.monthly_incomes[verificationMethodIndex] : selectedApplicant.monthly_income}
        </Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Non Recurring by Month</Typography>
        <Typography variant="body1">$0</Typography>
      </Stack>
    </>
  );
}
