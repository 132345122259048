import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    display: 'flex',
    padding: 32,
    flexDirection: 'column',
    backgroundColor: '#FFF',
    paddingBottom: 120,
  },
  header: {
    paddingBottom: 16,
    borderBottom: '1px solid #D4D7DC',
    marginBottom: 16,
  },
  logo: {
    width: 68,
    height: 16,
  },
  footerHelp: {
    width: 16,
    height: 16,
  },
  titletext: {
    color: '#212121',
    fontWeight: 600,
    fontSize: 24,
  },
  titleview: {
    marginTop: 12,
  },
  subtitle: {
    marginTop: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subtitleText: {
    color: '#52505E',
    fontSize: 12,
    fontWeight: 400,
  },
  headingText: {
    color: '#212121',
    fontSize: 14,
    fontWeight: 600,
  },
  footer: {
    position: 'absolute',
    bottom: 32,
    left: 32,
    paddingVertical: 16,
    borderBottom: '1px solid #D4D7DC',
    borderTop: '1px solid #D4D7DC',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: 4,
    alignContent: 'center',
    alignItems: 'center',
  },
  footerText: {
    color: '#646464',
    fontSize: 12,
    fontWeight: 400,
  },
  summaryTable: {
    marginTop: 16,
    border: '1px solid #E5E5E7',
    borderRadius: '8px',
  },
  summaryTableHeader: {
    backgroundColor: '#F5F5F5',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12,
    color: '#52505E',
  },
  summaryTableRow: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12,
  },
  summaryTableCol1: {
    flex: '0 0 31%',
    padding: 8,
    paddingLeft: 16,
  },
  summaryTableMid: {
    flex: '0 0 22%',
    padding: 8,
    paddingLeft: 16,
  },
  summaryTableCol4: {
    flex: '0 0 25%',
    padding: 8,
    paddingLeft: 16,
  },
  summaryTitle: {
    marginTop: 32,
  },
  summary: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  summaryRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 12,
  },

  summaryTable2: {
    marginTop: 16,
    border: '1px solid #E5E5E7',
    borderRadius: '8px',
  },
  summaryTable2Header: {
    backgroundColor: '#F5F5F5',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12,
  },
  summaryTable2Row: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12,
  },
  summaryTable2Col1: {
    flex: '0 0 25%',
    padding: 8,
    paddingLeft: 16,
  },
  summaryTable2Mid: {
    flex: '0 0 25%',
    padding: 8,
    paddingLeft: 16,
  },
  summaryTable2Col4: {
    flex: '0 0 25%',
    padding: 8,
    paddingLeft: 16,
  },
  textLight: {
    color: '#7A7A7A',
    fontSize: 12,
    fontWeight: 400,
  },
  textDarkBold: {
    color: '#212121',
    fontSize: 12,
    fontWeight: 600,
  },
  fraudError: {
    marginTop: 16,
    backgroundColor: '#FFEFEF',
    border: '1px solid #F29B9B',
    borderRadius: '4px',
    padding: '8px 12px',
  },
  fraudErrorText: {
    color: '#52505E',
    fontSize: 10,
    fontWeight: 400,
  },
  cleanDoc: {
    color: '#0C6B58',
    fontSize: 12,
    fontWeight: 500,
    padding: '2px 8px',
    backgroundColor: '#C6F7E2',
    borderRadius: '16px',
  },
  issuesDoc: {
    color: '#911111',
    fontSize: 12,
    fontWeight: 500,
    padding: '2px 8px',
    backgroundColor: '#FACDCD',
    borderRadius: '16px',
  },
});
