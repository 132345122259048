import { deleteRequest, getRequest, postRequest, putRequest } from './http.service';

const baseUrl = '/user';

export const addUser = (user: any) => {
  return postRequest(`${baseUrl}`, user);
};

export const getUserById = (userId: string) => {
  return getRequest(`${baseUrl}/${userId}`);
};

export const getAllUsers = (selectedProperty?: string, page?: number, pageSize?: number, search = '', active?: 'yes' | 'no') => {
  let queryParams = '';
  if (page && pageSize) {
    queryParams = `page=${page}&pageSize=${pageSize}`;
  }
  if (active) {
    queryParams += `&active=${active}`;
  }
  if (search) {
    queryParams += `&search=${search}`;
  }
  if (selectedProperty) {
    queryParams += `&property_id=${selectedProperty}`;
  }
  if (queryParams) {
    queryParams = `?${queryParams}`;
  }
  return getRequest(`${baseUrl + queryParams}`).then(res => res.data);
};

export const updateUser = (user: any) => {
  return putRequest(`${baseUrl}/${user._id}`, user);
};

export const deleteUser = (userId: string) => {
  return deleteRequest(`${baseUrl}/${userId}`);
};
