import {
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { forwardRef } from "react";
import { TextField } from "../input";
import palette from "../palette";

interface IFormInputField {
  label?: string;
  render?: any;
  labelElement?: any;
  ref: any;
}

export const FormInputField = forwardRef(
  ({ label, render, labelElement, ...props }: any, ref) => {
    return (
      <>
        <Stack spacing={1} sx={{ ...(props.stackSx || {}) }}>
          <label htmlFor={label + "_id"}>
            {labelElement ? (
              labelElement
            ) : (
              <Typography variant="body2" sx={{ color: palette.grey[80] }}>
                {label}
              </Typography>
            )}
          </label>
          {render ? (
            render
          ) : (
            <TextField
              id={label + "_id"}
              {...props}
              sx={{
                ...(props.sx || {}),
                "& input": {
                  backgroundColor: "white",
                  borderRadius: ".5rem",
                },
              }}
              inputRef={ref}
            />
          )}
        </Stack>
      </>
    );
  }
);

export const FormSelectField = forwardRef(
  ({ label, labelElement, children, ...props }: any, ref) => {
    return (
      <>
        <Stack spacing={1}>
          <label htmlFor={label + "_id"}>
            {labelElement ? (
              labelElement
            ) : (
              <Typography variant="body2" sx={{ color: palette.grey[80] }}>
                {label}
              </Typography>
            )}
          </label>
          <Select
            inputRef={ref}
            fullWidth
            id={label + "_id"}
            {...props}
            sx={{
              ...(props.sx || {}),
              backgroundColor: "white",
              borderRadius: ".5rem",
              "& .MuiSelect-select": {
                ...(props?.sx?.["& .MuiSelect-select"] || {}),
                py: 1.5,
              },
            }}
          >
            {children}
          </Select>
        </Stack>
      </>
    );
  }
);

export const FormInput = ({ label, render, labelElement, ...props }: any) => {
  return (
    <>
      <Stack spacing={1}>
        <label htmlFor={label + "_id"}>
          {labelElement ? (
            labelElement
          ) : (
            <Typography variant="body2" sx={{ color: palette.grey[80] }}>
              {label}
            </Typography>
          )}
        </label>
        {render}
      </Stack>
    </>
  );
};
