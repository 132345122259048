import { Box, ButtonBase, Grid, Stack, styled, Typography } from '@mui/material';
import palette from '../../../ui/palette';
import { ReactComponent as ReportsDashImg } from './../../../illustrations/images/reports_dash.svg';
import { ReactComponent as GoogleLogo } from './../../../illustrations/icons/google_logo.svg';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/user/actions';
import { ReactComponent as Logo } from './../../../illustrations/images/logo.svg';
import { signInWithGoogle } from '../../../firebase';
import { loginRegister } from '../../../services/auth.service';
import { setAuthToken } from '../../../services/http.service';

const SignInButton = styled(ButtonBase)(() => ({
  padding: '1rem 1.25rem',
  borderRadius: '.5rem',
  border: `1px solid ${palette.dark.tertiary}`,
  width: '100%',
  maxWidth: '26rem',
  fontSize: '1rem',
  lineHeight: 1.5,
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  gap: '.625rem',
}));

export default function Login() {
  const dispatch = useDispatch();

  const onClick = async () => {
    const res = await signInWithGoogle();
    const token = await res.user.getIdToken();
    const payload = {
      email: res.user?.email,
      googleId: res.user?.uid,
      name: res.user.displayName,
    };
    const loginRes = await loginRegister(payload);
    setAuthToken(token);
    dispatch(setUser(loginRes?.data?.user));
  };

  return (
    <Box sx={{ height: '100vh' }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={6} sx={{ height: '100%', px: 4 }}>
          <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <Logo />
            <Typography sx={{ fontSize: '2.5rem', lineHeight: 1.1, mb: '3.25rem', mt: 3 }}>Log Into your Account</Typography>
            <SignInButton onClick={onClick}>
              <GoogleLogo /> Sign in with Google
            </SignInButton>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            height: '100%',
            pl: 6,
            background: 'linear-gradient(158deg, #A9B4EE -4.62%, #E4E8FC 141.65%)',
            borderLeft: `1px solid ${palette.primary[40]}`,
          }}
        >
          <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ height: '100%' }}>
            <ReportsDashImg />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
