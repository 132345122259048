import { Text, View, Image } from '@react-pdf/renderer';
import { styles } from './styles';

export const Footer = () => {
  return (
    <View fixed style={styles.footer}>
      <Image src="https://proofup-assets.s3.us-east-1.amazonaws.com/help_outline.png" style={styles.footerHelp} />
      <Text style={styles.footerText}>If you have any questions, contact us for support at</Text>
      <Text style={{ ...styles.footerText, color: '#6078EA', fontWeight: 600 }}>support@proofup.com</Text>
    </View>
  );
};
