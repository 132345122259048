import { Stack, Typography, Link } from '@mui/material';
import { getVerificationMethod } from '../../../models/report';
import { VerificationMethodProps } from './BankIncomeMode';
import { PDFReport } from '../../../components/pdf-report';
import { BlobProvider } from '@react-pdf/renderer';

export default function DefaultVertificationMode({ selectedApplicant, application, verificationMethodIndex }: VerificationMethodProps) {
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Full Income Report PDF</Typography>
        {selectedApplicant.income_history[0].report_link ? (
          <Link href={selectedApplicant.income_history[0].report_link} target="_blank">
            <Typography variant="body1">View Report</Typography>
          </Link>
        ) : (
          <BlobProvider document={<PDFReport applicant={selectedApplicant} application={application} idx={verificationMethodIndex} />}>
            {({ blob, url, loading, error }) =>
              loading ? (
                'Loading document...'
              ) : (
                <Link href={url!!} target="_blank">
                  <Typography variant="body1">View Report</Typography>
                </Link>
              )
            }
          </BlobProvider>
        )}
      </Stack>
      {selectedApplicant.income_history[0].report_link ? (
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">Uploaded Files</Typography>
          <Link href={selectedApplicant.income_history[0].files_link} target="_blank">
            <Typography variant="body1">Download</Typography>
          </Link>
        </Stack>
      ) : (
        <>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Relationship</Typography>
            <Typography variant="body1">{selectedApplicant.role}</Typography>
          </Stack>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Method Used</Typography>
            <Typography variant="body1">{getVerificationMethod(selectedApplicant.verification_method[verificationMethodIndex])}</Typography>
          </Stack>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Attempts Made</Typography>
            <Typography variant="body1">{selectedApplicant.verification_attempts.length}</Typography>
          </Stack>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Attempt Number</Typography>
            <Typography variant="body1">{verificationMethodIndex + 1}</Typography>
          </Stack>
        </>
      )}
    </>
  );
}
