import { Box, List, Pagination, PaginationItem, Popover, Stack, Typography } from '@mui/material';
import { PopoverActionListItem } from '../../ui/popover';
import { ButtonCover } from '../../ui/button';
import { useState } from 'react';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import palette from '../../ui/palette';
import { rowsPerPageOptions } from '../../constants/constants';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export interface PageType {
  page: number;
  rowsPerPage: number;
}

interface PaginationProps {
  totalItems: number;
  pagination: PageType;
  setPagination: (p: PageType) => void;
}

export default function TablePagination({ totalItems, pagination, setPagination }: PaginationProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const pageCount = Math.ceil(totalItems / pagination.rowsPerPage);
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
        <Box>
          Rows per page
          <ButtonCover
            onClick={e => setAnchorEl(e.currentTarget)}
            sx={{
              ml: 1.5,
              py: 1,
              pl: 1,
              pr: 0.5,
              backgroundColor: palette.primary[10],
              borderRadius: '.25rem',
            }}
          >
            {pagination.rowsPerPage}
            <ExpandMoreRoundedIcon sx={{ ml: 0.5, height: '1.5rem', width: '1.5rem' }} />
          </ButtonCover>
        </Box>
        <Pagination
          count={pageCount}
          page={pagination.page}
          onChange={(_e, value: number) => {
            setPagination({ ...pagination, page: value });
          }}
          shape="rounded"
          renderItem={item => <PaginationItem slots={{ previous: PrevItem, next: NextItem }} {...item} />}
          sx={{ '& .MuiPagination-ul .Mui-selected': { backgroundColor: palette.primary[10] } }}
        />
      </Stack>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List sx={{ p: 6 / 8 }}>
          {rowsPerPageOptions.map(rppo => {
            return (
              <PopoverActionListItem
                key={rppo}
                onClick={() => {
                  setPagination({ page: 1, rowsPerPage: parseInt(rppo) });
                  setAnchorEl(null);
                }}
              >
                <Typography>{rppo}</Typography>
              </PopoverActionListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
}

const PrevItem = () => {
  return (
    <>
      <ChevronLeftIcon />
      Prev
    </>
  );
};

const NextItem = () => {
  return (
    <>
      Next
      <ChevronRightIcon />
    </>
  );
};
