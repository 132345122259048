import { Box, Checkbox, DialogContent, Grid, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import { User } from '../../../models/user';
import { Button } from '../../../ui/button';
import { Dialog } from '../../../ui/dialog';
import { FormInputField, FormSelectField } from '../../../ui/form';
import palette from '../../../ui/palette';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { Property } from '../../../models/property';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, notify, showLoader } from '../../../store/ui/actions';
import { addUser, updateUser } from '../../../services/user.service';
import { SelectChip } from '../../../ui/select';
import { getUser } from '../../../store/user/selector';

interface IAddEditUser {
  isEditMode?: boolean;
  user: User | null;
  handleClose: (refresh?: boolean) => void;
  open: boolean;
  propertiesIdMap: { [key: string]: Property };
  properties: Property[];
}

export default function AddEditUser({ isEditMode, user, handleClose, open, propertiesIdMap, properties = [] }: IAddEditUser) {
  const dispatch = useDispatch();
  const currentUser = useSelector(getUser);

  const {
    control,
    formState: { errors, isValid },
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      properties: [] as string[],
    },
    mode: 'onTouched',
  });

  useEffect(() => {
    if (isEditMode && user) {
      const { email, properties, role } = user;
      const [firstName, lastName] = (user?.name || '').split(' ');
      reset({ firstName, lastName, email, properties, role });
    }
  }, [user]);

  const onSubmit = async (data: any) => {
    try {
      dispatch(showLoader());
      if (isEditMode) {
        const payload = {
          ...user,
          ...data,
          name: data.firstName + ' ' + data.lastName,
        };
        delete payload.firstName;
        delete payload.lastName;
        const res = await updateUser(payload);
        dispatch(
          notify({
            severity: 'success',
            open: true,
            message: 'User has been updated',
          })
        );
        handleClose(true);
      } else {
        const payload = {
          ...data,
          name: data.firstName + ' ' + data.lastName,
        };
        delete payload.firstName;
        delete payload.lastName;
        const res = await addUser(payload);
        dispatch(
          notify({
            severity: 'success',
            open: true,
            message: 'User has been created.',
          })
        );
        handleClose(true);
      }
    } catch (err: any) {
      dispatch(
        notify({
          severity: 'error',
          open: true,
          message: err?.response?.data?.message || 'Something went wrong.',
        })
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const propertyToPropertyIdMap = useMemo(() => {
    return properties?.reduce(
      (acc, p) => {
        acc[p._id] = p;
        return acc;
      },
      {} as { [key: string]: Property }
    );
  }, [properties]);

  return (
    <Dialog
      size="lg"
      open={open}
      onClose={(ev, reason) => {
        if (reason && reason === 'backdropClick') {
          return;
        }
        handleClose();
      }}
    >
      <DialogContent
        sx={{
          '& .MuiDialogContent-root': {
            padding: 0,
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            mb: 4,
          }}
        >
          {isEditMode ? 'Edit User' : 'Add New User'}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={3} columnSpacing={4} sx={{ mb: 5 }}>
            <Grid item xs={6}>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: 'First Name is required.',
                }}
                render={({ field }) => {
                  return (
                    <FormInputField
                      label="First Name"
                      placeholder="Enter First Name"
                      {...field}
                      error={errors?.firstName}
                      helperText={errors?.firstName?.message}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: 'Last Name is required.',
                }}
                render={({ field }) => {
                  return (
                    <FormInputField
                      label="Last Name"
                      placeholder="Enter Last Name"
                      {...field}
                      error={errors?.lastName}
                      helperText={errors?.lastName?.message}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Email is required.',
                }}
                render={({ field }) => {
                  return (
                    <FormInputField label="Email" placeholder="Enter Email Address" {...field} error={errors?.email} helperText={errors?.email?.message} />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="role"
                control={control}
                rules={{
                  required: 'Role is required.',
                }}
                render={({ field }) => {
                  return (
                    <FormSelectField
                      label="Role"
                      displayEmpty
                      renderValue={field.value !== '' ? undefined : () => 'Select Role'}
                      {...field}
                      error={errors?.role}
                      helperText={errors?.role?.message}
                    >
                      <MenuItem value={'la'}>Leasing Agent</MenuItem>
                      {currentUser.role === 'admin' || currentUser.role === 'pm' ? <MenuItem value={'pm'}>Property Manager</MenuItem> : <></>}
                      {currentUser.role === 'admin' ? <MenuItem value={'admin'}>Admin</MenuItem> : <></>}
                    </FormSelectField>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="properties"
                control={control}
                render={({ field }) => {
                  return (
                    <FormSelectField
                      {...field}
                      label="Property"
                      multiple
                      renderValue={(selected: any) => {
                        if (!selected?.length) {
                          return 'Select Property';
                        }
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 6 / 8,
                            }}
                          >
                            {selected.map((value: any, index: number) => (
                              <SelectChip
                                key={value}
                                label={propertyToPropertyIdMap?.[value]?.name}
                                onDelete={() => {
                                  const copy = [...selected];
                                  copy.splice(index, 1);
                                  setValue('properties', copy);
                                }}
                              />
                            ))}
                          </Box>
                        );
                      }}
                    >
                      {(properties || []).map(property => (
                        <MenuItem key={property._id} value={property._id}>
                          <Checkbox checked={!!(field.value || []).find((p: any) => p === property._id)} />
                          <ListItemText primary={property.name} />
                        </MenuItem>
                      ))}
                    </FormSelectField>
                  );
                }}
              />
            </Grid>
          </Grid>

          <Stack direction="row" justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => handleClose()}
                variant="text"
                sx={{
                  py: 14 / 8,
                  px: 20 / 8,
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={!isValid}
                variant="contained"
                sx={{
                  minWidth: '160px',
                  py: 14 / 8,
                  px: 20 / 8,
                }}
              >
                {isEditMode ? 'Edit' : 'Add'} User
              </Button>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}
