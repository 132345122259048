export enum ReportStatus {
  NOT_STARTED = 'not_started',
  PENDING_REPORTS = 'pending',
  NEW_REPORTS = 'active',
  FLAGGED = 'flagged',
  CLOSED_REPORTS = 'closed',
}

export const REPORT_TABS = {
  [ReportStatus.NOT_STARTED]: 'Invited',
  [ReportStatus.PENDING_REPORTS]: 'Pending Reports',
  [ReportStatus.NEW_REPORTS]: 'New Reports',
  [ReportStatus.FLAGGED]: 'Flagged',
  [ReportStatus.CLOSED_REPORTS]: 'Closed Reports',
};
export enum ReportAction {
  REMOVE = 'Remove',
  ADD_FLAG = 'Add Flag',
  CLOSE_REPORT = 'Close Report',
  ADD_CO_SIGNER = 'Add Co Signer',
  REOPEN_REPORT = 'Re Open Report',
  VIEW_REPORT = 'View Report',
}

export const reportTabs = [ReportStatus.NOT_STARTED, ReportStatus.NEW_REPORTS, ReportStatus.CLOSED_REPORTS];

export interface Column {
  accessorKey: string;
  header: string;
  size: number;
  isActionColumn?: boolean;
  reportStatus: ReportStatus[];
  accessorFn?: any;
  hideColumn?: boolean;
}

export const reportColumns: Column[] = [
  {
    accessorKey: 'applicant_name',
    header: 'Applicant Name',
    size: 250,
    reportStatus: [ReportStatus.NOT_STARTED, ReportStatus.NEW_REPORTS, ReportStatus.CLOSED_REPORTS],
  },
  {
    accessorKey: 'unit',
    header: 'Unit #',
    size: 100,
    reportStatus: [ReportStatus.NOT_STARTED, ReportStatus.NEW_REPORTS, ReportStatus.CLOSED_REPORTS],
  },
  {
    accessorKey: 'monthly_rent',
    header: 'Rent',
    size: 50,
    reportStatus: [ReportStatus.NEW_REPORTS, ReportStatus.CLOSED_REPORTS],
  },
  {
    accessorKey: 'total_monthly_income',
    header: 'Income',
    size: 100,
    reportStatus: [ReportStatus.NEW_REPORTS, ReportStatus.CLOSED_REPORTS],
  },
  {
    accessorKey: 'income_level',
    header: 'Income Level',
    size: 100,
    reportStatus: [ReportStatus.NEW_REPORTS, ReportStatus.CLOSED_REPORTS],
  },
  {
    accessorKey: 'reports_generated',
    header: '# Reports',
    size: 50,
    reportStatus: [ReportStatus.NEW_REPORTS],
  },
  {
    accessorKey: 'recommendation',
    header: 'Recommendation',
    size: 150,
    reportStatus: [ReportStatus.NEW_REPORTS],
  },
  {
    accessorKey: 'flag_issue',
    header: 'Issue',
    size: 150,
    reportStatus: [ReportStatus.NEW_REPORTS],
  },
  {
    accessorKey: 'decision',
    header: 'Decision',
    size: 100,
    reportStatus: [ReportStatus.CLOSED_REPORTS],
  },
  {
    accessorKey: 'last_invite_sent',
    header: 'Invite Sent',
    size: 180,
    reportStatus: [ReportStatus.NOT_STARTED],
  },
  {
    accessorKey: 'verification_methods',
    header: 'Method',
    size: 200,
    reportStatus: [ReportStatus.NEW_REPORTS, ReportStatus.CLOSED_REPORTS],
  },
  {
    accessorKey: 'invite',
    header: 'Resend Invite',
    size: 210,
    isActionColumn: true,
    reportStatus: [ReportStatus.NOT_STARTED],
  },
];

export const recommendationStyles: Record<string, any> = {
  'Passes Requirements': {
    backgroundColor: '#E2F9EE !important',
    color: '#147D64 !important',
  },
  'Fails Requirements': {
    backgroundColor: '#FACDCD !important',
    color: '#911111 !important',
  },
  'Needs Review': {
    backgroundColor: '#FFF3C4 !important',
    color: '#CB6E17 !important',
  },
};

export const issueStyles: Record<string, any> = {
  'Insufficient Info': {
    backgroundColor: '#FFF3C4 !important',
    color: '#CB6E17 !important',
    fontWeight: 500,
  },
  'Disputed Results': {
    backgroundColor: '#F5F5F5 !important',
    color: '#52505E !important',
    fontWeight: 500,
  },
  'System Error': {
    backgroundColor: '#FFF3C4 !important',
    color: '#CB6E17 !important',
    fontWeight: 500,
  },
  'Linking Error': {
    backgroundColor: '#FFF3C4 !important',
    color: '#CB6E17 !important',
    fontWeight: 500,
  },
  'Fraud Detected': {
    backgroundColor: '#FACDCD !important',
    color: '#911111 !important',
    fontWeight: 500,
  },
};

export const decisionLabel: Record<string, string> = {
  'Application Passes Requirements': 'Passed',
  'Application Fails Requirements': 'Failed',
};

export const APPLICATION_STATUS: Record<string, string> = {
  [ReportStatus.NOT_STARTED]: 'Not Started',
  [ReportStatus.PENDING_REPORTS]: 'Report Pending',
  [ReportStatus.NEW_REPORTS]: 'Report Generated',
  [ReportStatus.FLAGGED]: 'Report Flagged',
  [ReportStatus.CLOSED_REPORTS]: 'Report Closed',
};

export const applicantLabelStyles: Record<string, any> = {
  [ReportStatus.NOT_STARTED]: {
    backgroundColor: '#F5F5F5 !important',
    color: '#52505E !important',
  },
  [ReportStatus.PENDING_REPORTS]: {
    backgroundColor: '#FFF3C4 !important',
    color: '#CB6E17 !important',
  },
  [ReportStatus.NEW_REPORTS]: {
    backgroundColor: '#C6F7E2 !important',
    color: '#147D64 !important',
  },
  [ReportStatus.FLAGGED]: {
    backgroundColor: '#FACDCD !important',
    color: '#911111 !important',
  },
  [ReportStatus.CLOSED_REPORTS]: {
    backgroundColor: '#F5F5F5 !important',
    color: '#52505E !important',
  },
};
