import { Box, Typography } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../illustrations/images/report_search.svg';

export default function EmptyTable() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ textAlign: 'center', pt: 10, pb: 15 }}>
        <SearchIcon />
        <Typography variant="h4" sx={{ mt: 3, fontWeight: 500 }}>
          No matching results found.
        </Typography>
        <Typography variant="h4">Please try again</Typography>
      </Box>
    </Box>
  );
}
