import { Image, Text, View } from '@react-pdf/renderer';
import { styles } from './styles';
import moment from 'moment';

export const Header = ({ applicant, application }: any) => {
  return (
    <View fixed style={styles.header}>
      <View>
        <Image src="https://proofup-assets.s3.amazonaws.com/proofup-logo.png" style={styles.logo} />
      </View>
      <View style={styles.titleview}>
        <Text style={styles.titletext}>Income Report</Text>
      </View>
      <View style={styles.subtitle}>
        <Text style={styles.subtitleText}>For {applicant.name}</Text>
        <Text style={styles.subtitleText}>Created on: {moment().format('MM/DD/YY')}</Text>
      </View>
      <View style={styles.subtitle}>
        <Text style={styles.subtitleText}>
          {application.property_name} | {application.unit}
        </Text>
        <Text style={styles.subtitleText}>ResProp Management</Text>
      </View>
    </View>
  );
};
