import { Box, Button, DialogActions, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Dialog } from '../../../ui/dialog';
import { useState } from 'react';

interface Props {
  onClose: VoidFunction;
  onSubmit: (reason: string) => void;
}

export default function ReopenReportDialog({ onClose, onSubmit }: Props) {
  const [selectedDecision, setSelectedDecision] = useState<string | null>(null);

  return (
    <Dialog fullWidth open onClose={onClose} PaperProps={{ sx: { maxWidth: '472px' } }}>
      <DialogTitle>Re-open Report</DialogTitle>
      <Box sx={{ px: 3, pb: 2 }}>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Select the reasoning on why you are re-opening the report.
        </Typography>
        <FormControl fullWidth>
          <InputLabel>Select an Option</InputLabel>
          <Select value={selectedDecision} label="Select an Decision" onChange={e => setSelectedDecision(e.target.value)}>
            {decisionList.map(decision => (
              <MenuItem key={decision.value} value={decision.value}>
                {decision.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <DialogActions sx={{ px: 3, pb: 3, gap: 1 }}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!selectedDecision} variant="contained" onClick={() => selectedDecision && onSubmit(selectedDecision)}>
          Re-open Report
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const decisionList = [
  { label: 'Applicant interest', value: 'Applicant interest' },
  { label: 'Additional Income data available', value: 'Additional Income data available' },
  { label: 'Add a co-signer', value: 'Add a co-signer' },
  { label: 'False negative (Analysis issue)', value: 'False negative (Analysis issue)' },
];
