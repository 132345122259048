import { logoutFirebase } from "../firebase";
import { getRequest, postRequest } from "./http.service";

const baseUrl = "/auth";

export const loginRegister = (payload: any) => {
  return postRequest(`${baseUrl}/authenticate`, payload);
};

export const getLoggedInUser = () => {
  return getRequest(`${baseUrl}/user`);
};

export const logoutUser = () => {
  localStorage.removeItem("auth_token");
  localStorage.removeItem("store");
  logoutFirebase();
  window.location.href = "/";
};
