const palette = {
  white: "white",
  primary: {
    10: "#F1F3FD",
    20: "#E4E8FC",
    30: "#6078EA",
    40: "#A9B4EE",
    50: "#6078EA",
    80: "#2C366B",
    90: "#131D53",
    100: "#E9F0FC",
  },
  secondary: {
    10: "#18A0FB",
    50: "#FA952B",
  },
  grey: {
    20: "#2c364d0a",
    40: "#7A7A7A",
    50: "#D4D7DC",
    60: "#272937",
    70: "#646464",
    80: "#52505E",
  },
  success: {
    10: "#E2F9EE",
    20: "#C6F7E2",
    60: "#27AB83",
    70: "#199473",
    80: "#147D64",
  },
  error: {
    20: "#FACDCD",
    60: "#BA2525",
    80: "#911111",
    90: "#A61B1B"
  },
  warning: {
    20: "#FFF3C4",
    70: "#DE911D",
    80: "#CB6E17",
  },
  text: {
    primary: "#212121",
    secondary: "#52505E",
  },
  dark: {
    tertiary: '#27293759',
    notes: {
      70: '52505E'
    }
  },
  background: {
    dark: {
      20: "#F5F5F5"
    },
  },
};

export default palette;
