import { SearchBox } from '../../../ui/input';
import { Box, Stack, InputAdornment, Card, Typography, List } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { TableBody, TableCell, TableHead, TableRow } from '../../../ui/table';
import MoreVertRounded from '@mui/icons-material/MoreVertRounded';
import { Button, ButtonCover } from '../../../ui/button';
import palette from '../../../ui/palette';
import { Popover, PopoverActionListItem } from '../../../ui/popover';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RoleIdMap, User } from '../../../models/user';
import { deleteUser, getAllUsers } from '../../../services/user.service';
import AddEditUser from '../add-edit-user';
import { getMyProperties } from '../../../services/property.service';
import { hideLoader, showLoader } from '../../../store/ui/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Property } from '../../../models/property';
import { getSelectedProperty, getUser } from '../../../store/user/selector';
import EmptyTable from '../../../components/empty-table';
import Pagination, { PageType } from '../../../components/pagination';
import ConfirmDialog from '../../../components/modals/confirm';

enum PopupType {
  EditUser = 'EditUser',
  AddUser = 'AddUser',
  DeleteUser = 'DeleteUser',
}

interface IUserActionProps {
  active: 'yes' | 'no';
  onEdit: () => void;
  onRemove: () => void;
}

function UserAction({ active, onEdit, onRemove }: IUserActionProps) {
  return (
    <List sx={{ p: 6 / 8, minWidth: '160px' }}>
      <PopoverActionListItem onClick={onEdit}>
        <Typography>Edit User</Typography>
      </PopoverActionListItem>
      <PopoverActionListItem onClick={onRemove}>
        <Typography>Remove User</Typography>
      </PopoverActionListItem>
    </List>
  );
}

interface IUserListProps {
  active: 'yes' | 'no';
}

export default function UserList({ active }: IUserListProps) {
  const dispatch = useDispatch();
  const selectedProperty = useSelector(getSelectedProperty);
  const currentUser = useSelector(getUser);
  const [properties, setProperties] = useState<Array<Property>>([]);

  const [pagination, setPagination] = useState<PageType>({
    page: 1,
    rowsPerPage: 25,
  });

  const [popover, setPopover] = useState<{
    anchorEl: HTMLButtonElement | null;
    id: string | null;
  }>({
    anchorEl: null,
    id: null,
  });
  const [searchText, setSearchText] = useState('');
  const [users, setUsers] = useState<Array<User>>([]);
  const [user, setUser] = useState<User | null>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [popup, setPopup] = useState<{
    isOpen: boolean;
    type: PopupType | null;
    user: User | null;
  }>({
    isOpen: false,
    type: null,
    user: null,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setPopover({ anchorEl: event.currentTarget, id });
  };

  const handleClose = () => {
    setPopover({
      anchorEl: null,
      id: null,
    });
  };

  const getUsers = async () => {
    dispatch(showLoader());
    try {
      const { users, totalItems } = await getAllUsers(
        currentUser.role !== 'admin' ? selectedProperty._id : '',
        pagination.page,
        pagination.rowsPerPage,
        searchText,
        active
      );
      setUsers(users || []);
      setTotalItems(totalItems || 0);
    } catch (err) {
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    getUsers();
  }, [active, pagination, searchText, selectedProperty]);

  const getProperties = async () => {
    dispatch(showLoader());
    try {
      const properties = await getMyProperties();
      setProperties(properties);
    } catch (err) {
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    getProperties();
  }, []);

  const onSearch = (value: string) => {
    setSearchText(value || '');
  };

  const open = Boolean(popover?.anchorEl);

  const renderUserActionDialogs = useCallback(() => {
    switch (popup.type) {
      case PopupType.EditUser:
        return (
          <AddEditUser
            properties={properties}
            propertiesIdMap={propertyToPropertyIdMap}
            isEditMode={true}
            open={popup.isOpen}
            user={popup.user}
            handleClose={refresh => handlePopupClose(refresh)}
          />
        );
      case PopupType.AddUser:
        return (
          <AddEditUser
            properties={properties}
            propertiesIdMap={propertyToPropertyIdMap}
            open={popup.isOpen}
            user={popup.user}
            handleClose={refresh => handlePopupClose(refresh)}
          />
        );
      case PopupType.DeleteUser:
        return (
          <ConfirmDialog
            title="Are You Sure You Want to Remove The User?"
            content="This action cannot be reversed."
            confirmText="Remove User"
            onClose={() => handlePopupClose(true)}
            onConfirm={() => {
              if (popup.user) {
                removeUser(popup.user._id);
              }
            }}
          />
        );
    }
  }, [popup]);

  const handlePopupClose = (refresh?: boolean) => {
    if (refresh) {
      getUsers();
    }
    setPopup({
      ...popup,
      isOpen: false,
      type: null,
    });
  };

  const propertyToPropertyIdMap = useMemo(() => {
    return properties?.reduce(
      (acc, p) => {
        acc[p._id] = p;
        return acc;
      },
      {} as { [key: string]: Property }
    );
  }, [properties]);

  const removeUser = async (userId: string) => {
    dispatch(showLoader());
    try {
      const res = await deleteUser(userId);
      getUsers();
    } catch (err) {
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          mt: 6,
          border: '1px solid #D4D7DC',
          borderRadius: '1.5rem',
          pt: 4,
          px: 3,
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <SearchBox
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSearch(event.target.value)}
            sx={{ width: '22.5rem' }}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      width: '2rem',
                      height: '1.5rem',
                      color: '#27293759',
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              setPopup({
                type: PopupType.AddUser,
                isOpen: true,
                user: null,
              });
            }}
          >
            <ControlPointRoundedIcon sx={{ mr: 10 / 8 }} />
            Add User
          </Button>
        </Stack>
        {users?.length ? (
          <TableContainer component={Paper} sx={{ mt: 5, border: 'none', boxShadow: 'none' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: '15vw' }}>Applicant</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Property</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(users || []).map((user, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Typography variant="body1">{user.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{user.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{RoleIdMap[user.role] || '-'}</Typography>
                    </TableCell>
                    <TableCell sx={{ maxWidth: '10vw' }}>
                      <Typography variant="body1">
                        {user.properties && user.properties.length ? user.properties.map(p => propertyToPropertyIdMap[p]?.name).join(', ') : '-'}
                        {/* {propertyToPropertyIdMap[user.properties?.[0] || ""]
                          ?.name || "-"} */}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      {currentUser.role !== 'la' ? (
                        <ButtonCover
                          onClick={e => {
                            handleClick(e, 'action-popover');
                            setUser(user);
                          }}
                          sx={{
                            p: 1,
                            borderRadius: '.5rem',
                            '&:hover': {
                              backgroundColor: palette.primary[10],
                            },
                          }}
                        >
                          <MoreVertRounded sx={{ color: palette.grey[40] }} />
                        </ButtonCover>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <EmptyTable />
        )}
      </Card>
      <Pagination totalItems={totalItems} pagination={pagination} setPagination={setPagination} />
      <Popover
        id={popover?.id as string}
        open={open && popover?.id === 'action-popover'}
        anchorEl={popover?.anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <UserAction
          active={active}
          onEdit={() =>
            setPopup({
              type: PopupType.EditUser,
              user,
              isOpen: true,
            })
          }
          onRemove={() => {
            setPopup({
              type: PopupType.DeleteUser,
              user,
              isOpen: true,
            });
          }}
        />
      </Popover>
      {renderUserActionDialogs()}
    </>
  );
}
