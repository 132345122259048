// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import { setAuthToken } from "./services/http.service";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCPt-1APgZGE_T20PO2268SyTqYRGdc3_s",
  authDomain: "proofup-9cf1d.firebaseapp.com",
  projectId: "proofup-9cf1d",
  storageBucket: "proofup-9cf1d.appspot.com",
  messagingSenderId: "963860544732",
  appId: "1:963860544732:web:5ce10cee7e9f4eadaac819",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    console.log(user)
    auth.currentUser?.getIdToken(true).then((token) => {
      setAuthToken(token);
    })
    // ...
  } else {
    // User is signed out
    // ...
  }
});

export const logoutFirebase = () => {
  auth.signOut();
}

const googleAuthProvider = new GoogleAuthProvider();
export const signInWithGoogle = () => {
  return signInWithPopup(auth, googleAuthProvider);
};
export default app;
