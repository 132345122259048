import { ButtonBase, styled, Button as MuiButton } from "@mui/material";
import palette from "../palette";

export const ButtonCover = styled(ButtonBase)({});

export const ButtonOutlined = styled(ButtonBase)(() => ({
  borderRadius: "1.5rem",
  border: "1px solid",
  borderColor: palette.primary[50],
  color: palette.primary[50],
  fontSize: ".875rem",
  fontWeight: 600,
  lineHeight: 1.43,
  padding: ".5rem 2rem",
}));

export const Button = styled(MuiButton)(() => ({
  boxShadow: "none",
  padding: ".5rem .875rem",
  borderRadius: ".5rem",
  fontSize: ".875rem",
  lineHeight: 1.43,
  fontWeight: 600,
}));
