import { TableHead as MuiTableHead, TableBody as MuiTableBody, TableCell as MuiTableCell, TableRow as MuiTableRow, styled } from '@mui/material';
import palette from '../palette';

export const TableHead = styled(MuiTableHead)(() => ({
  border: 'none',
  boxShadow: 'none',
  backgroundColor: palette.primary[10],
  '& th': {
    border: 'none',
    fontWeight: 400,
    paddingTop: '.625rem',
    paddingBottom: '.625rem',
    '&:first-child': {
      paddingLeft: '1.5rem',
      borderTopLeftRadius: '2rem',
      borderBottomLeftRadius: '2rem',
    },
    '&:last-child': {
      paddingRight: '1.5rem',
      borderTopRightRadius: '2rem',
      borderBottomRightRadius: '2rem',
    },
  },
}));

export const TableRow = styled(MuiTableRow)(() => ({
  borderRadius: '2rem',
  '& td': {
    padding: '.625rem 1rem',
    borderBottom: `1px solid ${palette.grey[50]}`,
    '&:first-child': {
      paddingLeft: '1.5rem',
    },
    '&:last-child': {
      paddingRight: '0rem',
    },
  },
  ':last-of-type': {
    '& td': {
      border: 'none',
    },
  },
}));

export const TableCell = styled(MuiTableCell)(() => ({}));

export const TableBody = styled(MuiTableBody)(() => ({
  border: 'none',
}));
