export enum RouteUrl {
  Home = '/',
  Login = '/login',
  Reports = '/reports',
  DisputeCenter = '/dispute-center',
  ManageUsers = '/manage-users',
  ManageProperties = '/manage-properties',
  Faq = '/faq',
  ApplicantDetail = '/applicant/:id',
}
