export interface IReport {
  // IApplication
  _id: string;
  application_id: string;
  application_number: string;
  status: string;
  unit: string;
  applicant_id: string;
  building: string;
  has_cosigner?: boolean;
  monthly_rent: number;
  property_code: string;
  property_id: string;
  property_name: string;
  applicants: IApplicant[];

  // Not defined yet on API
  report_link: string;
  files_link: string;
  clean_doc_count?: number;
  doc_uploaded_count?: number;
  application_date: any;
}

export interface IApplicant {
  email: string;
  application_date: string;
  last_invite_sent?: string;
  name: string;
  phone: string;
  role: string;
  income_history: any[];
  verification_attempts: any[];
  verification_method: any[];
  _id: string;
  monthly_income: any;
  require_income_verification: any;
  token?: any;
  monthly_incomes?: any[];
}

export enum ReportSortOptions {
  'newest' = 'newest',
  'oldest' = 'oldest',
  'dictionary' = 'dictionary',
}

export enum FilterType {
  'clean' = 'clean',
  'edited' = 'edited',
}

export const filterTypeQueryMap = {
  clean: { pdf_clean: true },
  edited: { pdf_clean: false },
};

export const getVerificationMethod = (method: string) => {
  switch (method) {
    case 'bank_income':
      return 'Bank Income';
    case 'payroll':
      return 'Payroll Link';
    case 'paystub':
      return 'Paystub';
    case 'w2':
      return 'W2';
    case '1099':
      return '1099';
  }
};
