import { Checkbox, DialogContent, FormControlLabel, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { Button } from '../../../ui/button';
import { Dialog } from '../../../ui/dialog';
import { FormInputField, FormSelectField } from '../../../ui/form';
import palette from '../../../ui/palette';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoader, notify, showLoader } from '../../../store/ui/actions';
import { Property } from '../../../models/property';
import { addProperty, updateProperty } from '../../../services/property.service';

interface IAddEditProperty {
  isEditMode?: boolean;
  property: Property | null;
  handleClose: (refresh?: boolean) => void;
  open: boolean;
  properties?: Property[];
}

export default function AddEditProperty({ isEditMode, property, handleClose, open, properties }: IAddEditProperty) {
  const dispatch = useDispatch();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      street: '',
      city: '',
      state: '',
      code: '',
      email: '',
      resman_id: '',
      auto_link_opted_in: true,
      active: false,
      income_level: 3,
    },
    mode: 'onTouched',
  });

  useEffect(() => {
    if (isEditMode && property) {
      const { name, city, state, street, code, email, resman_id, auto_link_opted_in, active, income_level } = property;
      reset({ name, city, state, street, code, email, resman_id, auto_link_opted_in, active, income_level });
    }
  }, [property]);

  const onSubmit = async (data: any) => {
    try {
      dispatch(showLoader());
      if (isEditMode) {
        const payload = {
          ...property,
          ...data,
        };
        const res = await updateProperty(payload);
        dispatch(
          notify({
            severity: 'success',
            open: true,
            message: 'Property has been updated',
          })
        );
        handleClose(true);
      } else {
        const payload = {
          ...data,
        };
        const res = await addProperty(payload);
        dispatch(
          notify({
            severity: 'success',
            open: true,
            message: 'Property has been created.',
          })
        );
        handleClose(true);
      }
    } catch (err: any) {
      dispatch(
        notify({
          severity: 'error',
          open: true,
          message: err?.response?.data?.message || 'Something went wrong.',
        })
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <Dialog
      size="lg"
      open={open}
      onClose={(ev, reason) => {
        if (reason && reason === 'backdropClick') {
          return;
        }
        handleClose();
      }}
    >
      <DialogContent
        sx={{
          '& .MuiDialogContent-root': {
            padding: 0,
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            mb: 4,
          }}
        >
          {isEditMode ? 'Edit Property' : 'Add New Property'}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={3} columnSpacing={4} sx={{ mb: 5 }}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: 'Name is required.',
                }}
                render={({ field }) => {
                  return (
                    <FormInputField
                      label="Property Name"
                      placeholder="Enter Property Name"
                      {...field}
                      error={errors?.name}
                      helperText={errors?.name?.message}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="street"
                control={control}
                rules={{
                  required: 'Street is required.',
                }}
                render={({ field }) => {
                  return (
                    <FormInputField
                      label="Street Address"
                      placeholder="Enter Street Address"
                      {...field}
                      error={errors?.street}
                      helperText={errors?.street?.message}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="city"
                control={control}
                rules={{
                  required: 'City is required.',
                }}
                render={({ field }) => {
                  return <FormInputField label="City" placeholder="Enter city" {...field} error={errors?.city} helperText={errors?.city?.message} />;
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="state"
                control={control}
                rules={{
                  required: 'State is required.',
                }}
                render={({ field }) => {
                  return (
                    <FormSelectField
                      label="State"
                      displayEmpty
                      renderValue={field.value !== '' ? undefined : () => 'Select State'}
                      {...field}
                      error={errors?.state}
                      helperText={errors?.state?.message}
                    >
                      <MenuItem value={'FL'}>Florida</MenuItem>
                      <MenuItem value={'SC'}>South Carolina</MenuItem>
                      <MenuItem value={'TX'}>Texas</MenuItem>
                    </FormSelectField>
                  );
                }}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <Controller
                name="code"
                control={control}
                rules={{
                  required: 'Code is required.',
                }}
                render={({ field }) => {
                  return <FormInputField label="Property Code" placeholder="Enter code" {...field} error={errors?.code} helperText={errors?.code?.message} />;
                }}
              />
            </Grid> */}
            <Grid item xs={6}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Email is required.',
                }}
                render={({ field }) => {
                  return <FormInputField label="Report Email" placeholder="Enter email" {...field} error={errors?.email} helperText={errors?.email?.message} />;
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="income_level"
                control={control}
                rules={{
                  required: 'Income Level',
                }}
                render={({ field }) => {
                  return (
                    <FormInputField
                      type="number"
                      label="Income Level"
                      placeholder="Income Level"
                      {...field}
                      error={errors?.income_level}
                      helperText={errors?.income_level?.message}
                    />
                  );
                }}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Controller
                name="resman_id"
                control={control}
                rules={{
                  required: 'resman_id is required.',
                }}
                render={({ field }) => {
                  return (
                    <FormInputField
                      label="Resman ID"
                      placeholder="Enter resman id"
                      {...field}
                      error={errors?.resman_id}
                      helperText={errors?.resman_id?.message}
                    />
                  );
                }}
              />
            </Grid> */}
            <Grid item xs={6}>
              <Controller
                name="auto_link_opted_in"
                control={control}
                render={({ field }) => {
                  console.log('field', field);
                  return <FormControlLabel control={<Checkbox checked={field.value} onChange={field.onChange} />} label="Opt for Auto Sending Link" />;
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="active"
                control={control}
                render={({ field }) => {
                  console.log('field', field);
                  return <FormControlLabel control={<Checkbox checked={field.value} onChange={field.onChange} />} label="Active" />;
                }}
              />
            </Grid>
          </Grid>

          <Stack direction="row" justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => handleClose()}
                variant="text"
                sx={{
                  py: 14 / 8,
                  px: 20 / 8,
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={!isValid}
                variant="contained"
                sx={{
                  minWidth: '160px',
                  py: 14 / 8,
                  px: 20 / 8,
                }}
              >
                {isEditMode ? 'Edit' : 'Add'} Property
              </Button>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}
