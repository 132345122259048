import React from 'react';
import { Document } from '@react-pdf/renderer';
import { Page1 } from './page1';
import { Page2 } from './page2';

// Create Document Component
export const PDFReport = ({ applicant, application, idx = 0 }: any) => (
  <Document>
    <Page1 applicant={applicant} application={application} idx={idx} />
    <Page2 applicant={applicant} application={application} idx={idx} />
  </Document>
);
