import { combineReducers } from "redux-immutable";
import { Reducer } from "redux";
import user from "./user/reducer";
import ui from "./ui/reducer";

const rootReducer: Reducer = combineReducers({
  user,
  ui,
});

export default rootReducer;
