import { NotificationProps } from "../../models/ui";
import { RootState } from "../types";

export const getShowLoader = (rootState: RootState): boolean => {
  const loaderCount = rootState.getIn(["ui", "loaderCount"]) as number;
  return loaderCount > 0 ? true : false;
};

export const getNotification = (
  rootState: RootState
): Partial<NotificationProps> => {
  return rootState.getIn(["ui", "notify"]) as Partial<NotificationProps>;
};
