import { Record } from "immutable";
import { getStore } from "../../utils/persist";
import { ActionTypes } from "./action-types";
import { IShowLoader, IHideLoader, IUIState, INotify } from "./types";

type Actions = IShowLoader | IHideLoader | INotify;

const record = Record<IUIState>({
  loaderCount: 0,
  notify: {},
});

class UIState extends record implements IUIState {}

export default function reducer(state = new UIState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.ShowLoader: {
      return state.set("loaderCount", state.loaderCount + 1);
    }
    case ActionTypes.HideLoader: {
      if (state.loaderCount > 0) {
        return state.set("loaderCount", state.loaderCount - 1);
      }
      break;
    }
    case ActionTypes.Notify:
      return state.set("notify", {
        ...(state.notify || {}),
        ...action.payload,
      });

    default: {
      return state;
    }
  }
}
