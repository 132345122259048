import { Property } from "../../models/property";
import { User } from "../../models/user";
import { RootState } from "../types";
import { IUserState } from "./types";

export const getUserState = (rootState: RootState): IUserState =>
  rootState.get("user").toJS();
export const getUser = (rootState: RootState): User =>
  rootState.getIn(["user", "userDetails"]) as User;
export const getSelectedProperty = (rootState: RootState): Property =>
  rootState.getIn(["user", "selectedProperty"]) as Property;
export const getProperties = (rootState: RootState): Array<Property> =>
  rootState.getIn(["user", "properties"]) as Array<Property>;