import { Property } from "./property";

export interface User {
  _id: string;
  name: string;
  email: string;
  role: string;
  properties: Array<string>;
  active: boolean;
}

export const RoleIdMap: { [key: string]: string } = {
  pm: "Property Manager",
  la: "Leasing Agent",
  admin: "Admin",
};
