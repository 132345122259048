import { Stack, Typography } from '@mui/material';
import { VerificationMethodProps } from './BankIncomeMode';
import DefaultVertificationMode from './DefaultVertificationMode';

export default function PaystubMode({ selectedApplicant, application, verificationMethodIndex }: VerificationMethodProps) {
  const selectedIncomeHistory = selectedApplicant.income_history[verificationMethodIndex] ?? selectedApplicant.income_history[0];

  return (
    <>
      <DefaultVertificationMode selectedApplicant={selectedApplicant} application={application} verificationMethodIndex={verificationMethodIndex} />
      {selectedApplicant.income_history[0].report_link ? (
        <></>
      ) : (
        <>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Number of Paystubs</Typography>
            <Typography variant="body1">{selectedIncomeHistory.payrollCount}</Typography>
          </Stack>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Number of Clean Docs</Typography>
            <Typography variant="body1">{selectedIncomeHistory.payrollCount - (selectedIncomeHistory.payStubErrorCount ?? 0)}</Typography>
          </Stack>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Number of Error Docs</Typography>
            <Typography variant="body1">{selectedIncomeHistory.payStubErrorCount}</Typography>
          </Stack>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Payment Frequency</Typography>
            <Typography variant="body1">{selectedIncomeHistory.payFreq}</Typography>
          </Stack>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Employer</Typography>
            <Typography variant="body1">{selectedIncomeHistory.employer}</Typography>
          </Stack>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Avg Gross Pay</Typography>
            <Typography variant="body1">${selectedIncomeHistory.avgGrossPay}</Typography>
          </Stack>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Avg Deductions</Typography>
            <Typography variant="body1">${selectedIncomeHistory.avgDeductions}</Typography>
          </Stack>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Avg Net Pay</Typography>
            <Typography variant="body1">${selectedIncomeHistory.avgNetPay}</Typography>
          </Stack>
        </>
      )}
    </>
  );
}
