import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducer from './reducer';
import { RootState } from './types';
import { configureStore } from '@reduxjs/toolkit';

const middlewares = [];

middlewares.push(thunk);

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    stateTransformer: (state: RootState) => state.toJS(),
  });

  middlewares.push(logger);
}

// const store = createStore(reducer, applyMiddleware(...middlewares));
// @ts-ignore
const store = configureStore({ reducer, middleware: middlewares });

store.subscribe(() => {
  localStorage.setItem('store', JSON.stringify(store.getState().toJS()));
});

export default store;
