import { Box, Typography } from '@mui/material';
import PropertyList from './property-list';

export default function ManageUsers() {
  return (
    <Box>
      <Box component="header">
        <Typography variant="h2" sx={{ mb: 8 }}>
          Manage Properties
        </Typography>
      </Box>
      <PropertyList />
    </Box>
  );
}
