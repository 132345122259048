import { Button, DialogContent, DialogTitle, Stack } from '@mui/material';
import { Dialog } from '../../../ui/dialog';

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  content: string;
  confirmText?: string;
  cancelText?: string;
}

export default function ConfirmDialog({ onClose, title, content, confirmText, cancelText, onConfirm }: Props) {
  return (
    <Dialog fullWidth open onClose={onClose} PaperProps={{ sx: { maxWidth: '472px' } }}>
      {title && <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>}
      <DialogContent sx={{ textAlign: 'center', pb: 0 }}>
        {content}
        <Stack flexDirection="row" sx={{ pt: 4, justifyContent: 'center', gap: 3 }}>
          <Button variant="outlined" onClick={onClose} sx={{ flex: 1, borderRadius: 2 }}>
            {cancelText || 'Cancel'}
          </Button>
          <Button variant="contained" color="error" onClick={onConfirm} sx={{ flex: 1, borderRadius: 2, boxShadow: 'unset', letterSpacing: '0.1px' }}>
            {confirmText || 'Yes'}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
