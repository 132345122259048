import { Box, Drawer, Stack, Tooltip, styled } from '@mui/material';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import { shouldForwardProp } from './../../utils/mui';
import palette from '../../ui/palette';
import { useLocation, useNavigate } from 'react-router';
import { RouteUrl } from '../../routes/types';
import { ReactComponent as LogoIcon } from './../../illustrations/icons/proof_up_logo_icon.svg';
import { useState } from 'react';
import LogoutConfirm from '../modals/logout';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/user/selector';

const IconContainer = styled(Box, {
  shouldForwardProp: shouldForwardProp(['active']),
})<{ active?: boolean }>(({ active }) => ({
  borderRadius: '2px',
  padding: '.25rem',
  width: '2rem',
  height: '2rem',
  cursor: 'pointer',
  backgroundColor: active ? palette.primary[40] : 'transparent',
  '& svg': {
    color: active ? palette.primary[80] : palette.text.secondary,
    height: '1.5rem',
    width: '1.5rem',
  },
}));

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useSelector(getUser);

  const [logoutConfirmOpen, setLogoutConfirmOpen] = useState(false);

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            boxSizing: 'border-box',
            backgroundColor: palette.primary[10],
            height: '100%',
            top: 'unset',
            borderRight: '1px solid #E4E8FC',
          },
        }}
      >
        <Stack sx={{ pt: 3, pb: 5, px: 2, height: '100%' }} justifyContent="space-between">
          <Stack alignItems="center">
            <LogoIcon style={{ height: '1.5rem', width: '1.5rem', marginBottom: '3rem' }} />
            <Stack spacing={3.5}>
              <Tooltip title="Reports">
                <IconContainer active={location.pathname === RouteUrl.Reports} onClick={() => navigate('/reports')}>
                  <ArticleOutlinedIcon />
                </IconContainer>
              </Tooltip>
              <Tooltip title="Manage Users">
                <IconContainer active={location.pathname === RouteUrl.ManageUsers} onClick={() => navigate('/manage-users')}>
                  <ManageAccountsOutlinedIcon />
                </IconContainer>
              </Tooltip>
              {currentUser.role === 'admin' ? (
                <Tooltip title="Manage Properties">
                  <IconContainer onClick={() => navigate('/manage-properties')} active={location.pathname === RouteUrl.ManageProperties}>
                    <HolidayVillageOutlinedIcon />
                  </IconContainer>
                </Tooltip>
              ) : (
                <></>
              )}
              <Tooltip title="FAQ">
                <IconContainer active={location.pathname === RouteUrl.Faq} onClick={() => navigate('/faq')}>
                  <HelpCenterOutlinedIcon />
                </IconContainer>
              </Tooltip>
            </Stack>
          </Stack>

          <Stack spacing={4}>
            <Tooltip title="Log Out">
              <IconContainer onClick={() => setLogoutConfirmOpen(true)}>
                <ExitToAppRoundedIcon sx={{ height: '2rem', width: '2rem' }} />
              </IconContainer>
            </Tooltip>
          </Stack>
        </Stack>
      </Drawer>
      <LogoutConfirm open={logoutConfirmOpen} handleClose={() => setLogoutConfirmOpen(false)} />
    </>
  );
}
