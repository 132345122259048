import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/routes";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "./store/user/selector";
import { useEffect } from "react";
import { setUser } from "./store/user/actions";
import { getNotification, getShowLoader } from "./store/ui/selector";
import { Alert, Box, CircularProgress, Snackbar } from "@mui/material";
import { notify } from "./store/ui/actions";
import { getLoggedInUser } from "./services/auth.service";
import { hasToken } from "./services/http.service";

function App() {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const notification = useSelector(getNotification);
  const showLoader = useSelector(getShowLoader);

  const getCurrentUser = async () => {
    try {
      const { data } = await getLoggedInUser();
      if (data?.user) {
        dispatch(setUser(data?.user));
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (hasToken()) {
      getCurrentUser();
    }
  }, []);

  return (
    <>
      {showLoader ? (
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
      <BrowserRouter>
        <AppRoutes isAuthenticated={!!user?.name} />
      </BrowserRouter>
      <Snackbar
        {...notification}
        onClose={() =>
          dispatch(
            notify({
              open: false,
            })
          )
        }
      >
        <Alert severity={notification?.severity}>{notification?.message}</Alert>
      </Snackbar>
    </>
  );
}

export default App;
