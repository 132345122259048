import { Box, Button, DialogActions, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Dialog } from '../../../ui/dialog';
import { useState } from 'react';

interface Props {
  onClose: VoidFunction;
  onSubmit: (flag: string) => void;
}

export default function FlagDialog({ onClose, onSubmit }: Props) {
  const [selectedFlag, setSelectedFlag] = useState<string | null>(null);

  return (
    <Dialog fullWidth open onClose={onClose} PaperProps={{ sx: { maxWidth: '472px' } }}>
      <DialogTitle>Flag Application</DialogTitle>
      <Box sx={{ px: 3, pb: 4 }}>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Select the reasoning on why you are flagging the Prospect's application.
        </Typography>
        <FormControl fullWidth>
          <InputLabel>Select an Option</InputLabel>
          <Select value={selectedFlag} label="Select an Option" onChange={e => setSelectedFlag(e.target.value)}>
            {flagList.map(flag => (
              <MenuItem key={flag.value} value={flag.value}>
                {flag.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <DialogActions sx={{ px: 3, pb: 3, gap: 1 }}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!selectedFlag} variant="contained" onClick={() => selectedFlag && onSubmit(selectedFlag)}>
          Flag Report
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const flagList = [
  { label: 'Insufficient Info', value: 'Insufficient Info' },
  { label: 'Applicant is Disputing Report', value: 'Disputed Results' },
  { label: 'System Error', value: 'System Error' },
  { label: 'Linking Issue', value: 'Linking Error' },
  { label: 'Fraud Detected', value: 'Fraud Detected' },
];
