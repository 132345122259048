import { styled, Tabs as MuiTabs } from '@mui/material';
import palette from '../palette';

export const Tabs = styled(MuiTabs)(() => ({
  '& .MuiTabs-flexContainer button.Mui-selected': {
    color: palette.text.primary,
    fontWeight: 500,
  },
  '& .MuiTabs-indicator': {
    height: '2px',
    borderRadius: '.125rem',
    backgroundColor: '#4B5DB8',
    zIndex: 10000,
  },
  '& .MuiTabs-flexContainer': {
    gap: '1.5rem',
  },
  '& .MuiTabs-flexContainer button': {
    fontSize: '1rem',
    lineHeight: 1.5,
    fontWeight: 400,
    padding: '0 0 1rem 0',
    textTransform: 'none',
    color: palette.grey[70],
  },
}));
