import { Box, Button, DialogActions, DialogTitle, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { Dialog } from '../../../ui/dialog';
import { useState } from 'react';

interface Props {
  onClose: VoidFunction;
  onSubmit: VoidFunction;
}

export default function AddCoSignerDialog({ onClose, onSubmit }: Props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  return (
    <Dialog fullWidth open onClose={onClose} PaperProps={{ sx: { maxWidth: '472px' } }}>
      <DialogTitle>Add a Co-signer</DialogTitle>
      <Stack gap={3} sx={{ px: 3, pb: 2 }}>
        <Typography variant="body1">Enter in the contact information of the co-signer, we will then send them a invite to verify their income.</Typography>
        <Box>
          <InputLabel sx={{ mb: 1 }}>Name</InputLabel>
          <OutlinedInput fullWidth value={name} onChange={e => setName(e.target.value)} placeholder="Enter Name of Co-signer" />
        </Box>
        <Box>
          <InputLabel sx={{ mb: 1 }}>Email</InputLabel>
          <OutlinedInput fullWidth value={email} type="email" onChange={e => setEmail(e.target.value)} placeholder="Enter Email of Co-signer" />
        </Box>
      </Stack>
      <DialogActions sx={{ px: 3, pb: 3, gap: 1 }}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Send Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}
