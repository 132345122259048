import { Box, Button, DialogActions, DialogTitle, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { Dialog } from '../../ui/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { getProperties } from '../../store/user/selector';
import { useState } from 'react';
import { getUnits } from '../../services/property.service';
import { createTransferApplication } from '../../services/application.service';
import { notify } from '../../store/ui/actions';

export default function TransferPopup({ onClose }: any) {
  const properties = useSelector(getProperties);
  const [fromUnits, setFromUnits] = useState([] as any[]);
  const [toUnits, setToUnits] = useState([] as any[]);

  const [selectedFromProperty, setSelectedFromProperty] = useState('');
  const [selectedFromUnit, setSelectedFromUnit] = useState('');
  const [selectedToProperty, setSelectedToProperty] = useState('');
  const [selectedToUnit, setSelectedToUnit] = useState('');
  const [rent, setRent] = useState('');

  const dispatch = useDispatch();

  const getUnitsForProp = async (propertyId: string, target: string) => {
    const units = await getUnits(propertyId);
    if (target === 'from') {
      setSelectedFromProperty(propertyId);
      setFromUnits(units.data);
    } else {
      setSelectedToProperty(propertyId);
      setToUnits(units.data);
    }
  };

  const onSubmit = async () => {
    try {
      await createTransferApplication({
        selectedFromProperty,
        selectedFromUnit,
        selectedToProperty,
        selectedToUnit,
        type: 'transfer',
        rent,
      });
      dispatch(
        notify({
          severity: 'success',
          open: true,
          message: 'Verification application created successfully.',
        })
      );
    } catch (e: any) {
      console.log(e);
      dispatch(
        notify({
          severity: 'error',
          open: true,
          message: e.response.data.message,
        })
      );
    }
    onClose();
  };
  return (
    <Dialog fullWidth open onClose={onClose} PaperProps={{ sx: { maxWidth: '472px' } }}>
      <DialogTitle>Transfer Application</DialogTitle>
      <Stack gap={3} sx={{ px: 3, pb: 2 }}>
        <Typography variant="body1">Complete the transfer verification application, we will then send them a invite to verify their income.</Typography>
        <Typography variant="subtitle1">Transfer From (Current Unit details):</Typography>
        <Box>
          <InputLabel sx={{ mb: 1 }}>Property</InputLabel>
          <Select
            fullWidth
            sx={{
              backgroundColor: 'white',
              borderRadius: '.5rem',
              '& .MuiSelect-select': {
                py: 1.5,
              },
            }}
            onChange={ev => {
              getUnitsForProp(ev.target.value as string, 'from');
            }}
          >
            {properties.map(property => (
              <MenuItem value={property._id}>{property.name}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel sx={{ mb: 1 }}>Unit</InputLabel>
          <Select
            fullWidth
            sx={{
              backgroundColor: 'white',
              borderRadius: '.5rem',
              '& .MuiSelect-select': {
                py: 1.5,
              },
            }}
            onChange={ev => {
              setSelectedFromUnit(ev.target.value as string);
            }}
          >
            {fromUnits.map(unit => (
              <MenuItem value={unit.unitNumber}>{unit.unitNumber}</MenuItem>
            ))}
          </Select>
        </Box>
        <Typography variant="subtitle1">Transfer To (Future Unit details):</Typography>
        <Box>
          <InputLabel sx={{ mb: 1 }}>Property</InputLabel>
          <Select
            fullWidth
            sx={{
              backgroundColor: 'white',
              borderRadius: '.5rem',
              '& .MuiSelect-select': {
                py: 1.5,
              },
            }}
            onChange={ev => {
              getUnitsForProp(ev.target.value as string, 'to');
            }}
          >
            {properties.map(property => (
              <MenuItem value={property._id}>{property.name}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel sx={{ mb: 1 }}>Unit</InputLabel>
          <Select
            fullWidth
            sx={{
              backgroundColor: 'white',
              borderRadius: '.5rem',
              '& .MuiSelect-select': {
                py: 1.5,
              },
            }}
            onChange={ev => {
              setSelectedToUnit(ev.target.value as string);
            }}
          >
            {toUnits.map(unit => (
              <MenuItem value={unit.unitNumber}>{unit.unitNumber}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel sx={{ mb: 1 }}>Rent</InputLabel>
          <OutlinedInput fullWidth value={rent} type="text" onChange={e => setRent(e.target.value)} placeholder="Enter Rent for new unit" />
        </Box>
      </Stack>
      <DialogActions sx={{ px: 3, pb: 3, gap: 1 }}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit} disabled={!selectedFromProperty || !selectedFromUnit || !selectedToProperty || !selectedToUnit || !rent}>
          Send Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}
