import { Property } from "../../models/property";
import { User } from "../../models/user";
import { ActionTypes } from "./action-types";
import { ISetProperties, ISetSelectedProperty, ISetUser } from "./types";

export const setUser = (user: User | null): ISetUser => ({
  type: ActionTypes.SetUser,
  payload: {
    user,
  },
});

export const setSelectedProperty = (
  property: Property | null
): ISetSelectedProperty => ({
  type: ActionTypes.SetSelectedProperty,
  payload: {
    property,
  },
});

export const setProperties = (
  properties: Array<Property> | []
): ISetProperties => ({
  type: ActionTypes.SetProperties,
  payload: {
    properties,
  },
});