import { Box, Button, Card, Stack, styled, Tab, Typography } from '@mui/material';
import palette from '../../ui/palette';
import { useEffect, useState } from 'react';
import ReportList from './report-list';
import PropertySelector from '../../components/property-selector';
import { Tabs } from '../../ui/tabs.ts';
import { REPORT_TABS, ReportStatus, reportTabs } from './constants';
import TablePagination, { PageType } from '../../components/pagination';
import { useLocation, useNavigate } from 'react-router';
import { RouteUrl } from '../../routes/types';
import { getApplicationSummary } from '../../services/application.service';
import { getSelectedProperty } from '../../store/user/selector';
import { useSelector } from 'react-redux';
import TransferPopup from '../../components/transfer-popup';

const DataCard = styled(Box)(() => ({
  backgroundColor: '#E4E8FC',
  borderRadius: '.5rem',
  textAlign: 'center',
  paddingTop: '1.25rem',
  paddingBottom: '1rem',
  width: '25%',
  '& h3': {
    fontWeight: 500,
    marginBottom: '.5rem',
  },
  '& h5': {
    fontWeight: 400,
  },
  '& .MuiSvgIcon-root': {
    height: '2.5rem',
    width: '2.5rem',
    marginBottom: '1rem',
  },
}));

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Reports() {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedProperty = useSelector(getSelectedProperty);
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get('status');
  const [selectedTab, setSelectedTab] = useState<ReportStatus | null>((status || ReportStatus.NEW_REPORTS) as ReportStatus);
  const [pagination, setPagination] = useState<PageType>({
    page: 1,
    rowsPerPage: 25,
  });
  const [summary, setSummary] = useState<{ applicationsCount: number; flaggedCount: number; meetReqCount: number; reportsMadeCount: number }>({
    applicationsCount: 0,
    flaggedCount: 0,
    meetReqCount: 0,
    reportsMadeCount: 0,
  });
  const [totalItems, setTotalItems] = useState<number>(0);

  const [showTransferPopup, setShowTransferPopup] = useState(false);

  const fetchSummary = async () => {
    const { data } = await getApplicationSummary(selectedProperty?.code === 'all' ? '' : selectedProperty?.code);
    setSummary(data);
  };

  useEffect(() => {
    if (status) {
      setSelectedTab(status as ReportStatus);
    }
  }, [status]);

  useEffect(() => {
    fetchSummary();
  }, [selectedProperty]);

  return (
    <>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box component="header" sx={{ display: 'flex', alignItems: 'stretch', gap: '.75rem' }}>
            <Typography variant="h2">Reports</Typography>
            <Box
              sx={{
                width: '1px',
                my: 2 / 8,
                backgroundColor: palette.dark.tertiary,
              }}
            />
            <PropertySelector />
          </Box>
          <Button
            title="Flag Report"
            sx={{ backgroundColor: palette.primary['10'], color: palette.primary['30'], px: 2, borderRadius: 2 }}
            onClick={() => setShowTransferPopup(true)}
          >
            Transfer Application
          </Button>
        </Box>
        <Stack component="summary" direction="row" sx={{ mt: 4 }} justifyContent="space-between" spacing={3}>
          <DataCard>
            <Typography variant="h3" color="primary.dark">
              {summary.applicationsCount}
            </Typography>
            <Typography variant="h5">Applications</Typography>
          </DataCard>
          <DataCard>
            <Typography variant="h3" color="primary.dark">
              {summary.reportsMadeCount}
            </Typography>
            <Typography variant="h5">Reports Made</Typography>
          </DataCard>
          <DataCard>
            <Typography variant="h3" color="primary.dark">
              {summary.meetReqCount}
            </Typography>
            <Typography variant="h5">Meet Requirements</Typography>
          </DataCard>
          <DataCard>
            <Typography variant="h3" color="primary.dark">
              {summary.flaggedCount}
            </Typography>
            <Typography variant="h5">Flagged Reports</Typography>
          </DataCard>
        </Stack>

        <Card
          variant="outlined"
          sx={{
            mt: 4,
            border: 1,
            borderColor: 'grey.500',
            borderRadius: '1.5rem',
            pt: 4,
            px: 3,
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <Tabs
              value={selectedTab}
              onChange={(_e, value) => {
                setSelectedTab(null);
                navigate(RouteUrl.Reports + `?status=${value}`);
              }}
            >
              {reportTabs.map((reportTab, index) => (
                <Tab value={reportTab} label={REPORT_TABS[reportTab]} {...a11yProps(index)} />
              ))}
            </Tabs>
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                height: '2px',
                backgroundColor: palette.background.dark[20],
              }}
            />
          </Box>

          {selectedTab && (
            <ReportList
              reportTab={selectedTab}
              page={pagination.page}
              pageSize={pagination.rowsPerPage}
              onUpdate={({ totalItems }) => {
                setTotalItems(totalItems);
              }}
            />
          )}
        </Card>

        <TablePagination pagination={pagination} setPagination={setPagination} totalItems={totalItems} />
      </Box>
      {showTransferPopup && (
        <TransferPopup
          onClose={() => {
            setShowTransferPopup(false);
            fetchSummary();
            setSelectedTab(ReportStatus.NOT_STARTED);
          }}
        />
      )}
    </>
  );
}
