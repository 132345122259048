import { Accordion as MuiAccordian, Stack, styled } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid } from '@mui/material';
import palette from '../../ui/palette';

const Accordion = styled(MuiAccordian)(() => ({
  padding: '1.5rem',
  border: 'none',
  boxShadow: 'none',
  borderRadius: '1rem',
  '&.MuiAccordion-root': {
    borderRadius: '1rem',
  },
  backgroundColor: palette.primary[10],
  '& svg': {
    color: palette.grey[80],
    height: '1.5rem',
    width: '1.5rem',
  },
  '::before': {
    content: 'none',
  },
}));

export default function Faq() {
  return (
    <Box sx={{ minHeight: 'calc(100vh - 80px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box>
        <Box component="header">
          <Typography
            sx={{
              fontSize: '2.5rem',
              fontWeight: 500,
              lineHeight: 1.1,
              mb: 6,
            }}
          >
            Frequently Asked Questions
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Stack spacing={3}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="h5" sx={{ fontWeight: 500 }}>
                    How long does the report take to generate?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The verification report generation time depends on the method used. If the applicant linked their bank account, the report is available
                    within minutes through automated data retrieval. If pay stubs were manually uploaded, it takes up to one hour to review the documentation
                    and generate the report to ensure accuracy.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="h5" sx={{ fontWeight: 500 }}>
                    What should I do if there is an error in the report?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    If you identify an error in the report, flag the application by selecting the "system error" option. When the report gets flagged for an
                    error or issue it will also alert our support team to look further into the issue.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="h5" sx={{ fontWeight: 500 }}>
                    How long does this take an applicant to complete?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The verification process for the applicant is meant to be quick and easy. The process of verifying their income should take 5 minuets or
                    less.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="h5" sx={{ fontWeight: 500 }}>
                    Can I upload on the prospect's behalf?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The self-service option for leasing agents to upload applicant documents on their behalf has been removed. By integrating with Plaid,
                    applicants can now directly link their bank accounts, streamlining the verification process. This approach improves both convenience for
                    applicants and the accuracy of the income data we receive.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="h5" sx={{ fontWeight: 500 }}>
                    How can I send a link to an applicant to complete?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Once an applicant pays the application fee, they will automatically receive an email inviting them to verify their income for the property.
                    You can view all the sent invitations in the "Not Started" tab. Additionally, you can resend the invite to the applicant by clicking "Resend
                    Invite."
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="h5" sx={{ fontWeight: 500 }}>
                    What if an applicant doesn't want to proceed with proofup?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The applicant must verify their income through proofup unless they are using an offer letter as proof of income. In this case, you can
                    verify employment by contacting the employer.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ bottom: 0, pt: 5, textAlign: 'center' }}>
        <Typography variant="body1">
          Contact <a href="mailto:support@proofup.com">support@proofup.com</a> if you have any questions, feedback, or issues.
        </Typography>
      </Box>
    </Box>
  );
}
