import { Record } from "immutable";
import { getStore } from "../../utils/persist";
import { ActionTypes } from "./action-types";
import { ISetProperties, ISetSelectedProperty, ISetUser, IUserState } from "./types";
import { FullStory } from '@fullstory/browser';


type Actions = ISetUser | ISetSelectedProperty | ISetProperties;

const persistedStore = getStore();

const record = Record<IUserState>({
  userDetails: persistedStore?.user?.userDetails ||  null,
  selectedProperty: persistedStore?.user?.selectedProperty || null,
  properties: persistedStore?.user?.properties || []
});

class UserState extends record implements IUserState {}

export default function reducer(state = new UserState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetUser: {
      FullStory('setIdentity', {
        uid: action.payload.user?._id,
        properties: {
          email: action.payload.user?.email,
          role: action.payload.user?.role
        }
      })
      return state.set("userDetails", action.payload.user);
    }
    case ActionTypes.SetSelectedProperty: {
      return state.set("selectedProperty", action.payload.property);
    }
    case ActionTypes.SetProperties: {
      return state.set("properties", action.payload.properties);
    }
    default: {
      return state;
    }
  }
}
