import { Box, Button, DialogActions, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Dialog } from '../../../ui/dialog';
import { useState } from 'react';

interface Props {
  onClose: VoidFunction;
  onSubmit: (reason: string) => void;
}

export default function CloseReportDialog({ onClose, onSubmit }: Props) {
  const [selectedDecision, setSelectedDecision] = useState<string | null>(null);

  return (
    <Dialog fullWidth open onClose={onClose} PaperProps={{ sx: { maxWidth: '472px' } }}>
      <DialogTitle>Close Report</DialogTitle>
      <Box sx={{ px: 3, pb: 2 }}>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Select what decision you have made on the applicant(s) income level.
        </Typography>
        <FormControl fullWidth>
          <InputLabel>Select an Decision</InputLabel>
          <Select value={selectedDecision} label="Select an Decision" onChange={e => setSelectedDecision(e.target.value)}>
            {decisionList.map(decision => (
              <MenuItem key={decision.value} value={decision.value}>
                {decision.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <DialogActions sx={{ px: 3, pb: 3, gap: 1 }}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!selectedDecision} variant="contained" onClick={() => selectedDecision && onSubmit(selectedDecision)}>
          Save & Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const decisionList = [
  { label: 'Application Passes Requirements', value: 'Application Passes Requirements' },
  { label: 'Application Fails Requirements', value: 'Application Fails Requirements' },
];
