import { Box } from '@mui/material';
import Sidebar from '../sidebar';

export default function Layout(props: any) {
  return (
    <>
      <Box sx={{ height: '100vh', position: 'relative', pl: 8 }}>
        <Sidebar />
        <Box sx={{ py: 5, pl: 4, pr: 3 }}>{props?.children}</Box>
      </Box>
    </>
  );
}
