import MUIDialog, { DialogProps } from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

enum sizeMap {
  xs = 360,
  sm = 440,
  md = 488,
  lg = 552,
}

export interface IDialogProps extends DialogProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  grey?: boolean;
  contentSpacing?: number;
  styles?: any;
}

const shouldForwardProp = (prop: string) => !['size', 'grey', 'contentSpacing'].includes(prop);

export const Dialog = styled(MUIDialog, { shouldForwardProp })<IDialogProps>(({ theme, size = 'md', grey, contentSpacing = 2, styles = {}, maxWidth }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.70)',
  },
  '& .MuiDialogTitle-root': {
    paddingBottom: '12px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5, 5),
    paddingBottom: theme.spacing(4),
  },
  '& .MuiDialogActions-root': {
    button: {
      borderRadius: theme.spacing(1),
    },
  },
  '& .MuiPaper-root': {
    //   backgroundColor: grey ? theme.palette.grey[100] : 'white',
    margin: theme.spacing(2),
    borderRadius: theme.spacing(size === 'xs' ? 3 : 2),
    '&.MuiDialog-paperFullScreen': {
      borderRadius: 0,
      margin: 0,
      width: '100%',
      maxWidth: '100%',
      '& .MuiDialogContent-root': {
        padding: 0,
      },
      // backgroundColor: '#f5f7fa',
    },
    ...styles,
  },

  [theme.breakpoints.down('md')]: {
    '& .MuiPaper-root:not(.MuiDialog-paperFullScreen)': {
      width: '100%',
      maxWidth: 'calc(100% - 32px)',
    },
  },

  [theme.breakpoints.up('md')]: {
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(2),
      margin: theme.spacing(14, 2),
      width: '100%',
      ...(maxWidth ? {} : { maxWidth: sizeMap[size] }),
    },
  },
}));
