import { Page, Text, View } from '@react-pdf/renderer';
import { styles } from './styles';
import { Header } from './header';
import { Footer } from './footer';
import moment from 'moment';
import { getVerificationMethod } from '../../models/report';

export const Page1 = ({ applicant, application, idx }: any) => {
  return (
    <Page size="A4" style={styles.page}>
      <Header applicant={applicant} application={application} />
      {applicant.verification_method[idx] === 'bank_income' ? (
        <>
          <View>
            <Text style={styles.headingText}>Net Income Summary</Text>
          </View>
          <View style={styles.summaryTable}>
            <View style={styles.summaryTableHeader}>
              <Text style={styles.summaryTableCol1}>Period</Text>
              <Text style={styles.summaryTableMid}>Recurring</Text>
              <Text style={styles.summaryTableMid}>Non Recurring</Text>
              <Text style={styles.summaryTableCol4}>Total</Text>
            </View>
            <View style={styles.summaryTableRow}>
              <Text style={{ ...styles.summaryTableCol1, color: '#646464' }}>Past 3-month avg.</Text>
              <Text style={{ ...styles.summaryTableMid, color: '#212121' }}>
                ${applicant.monthly_incomes?.length ? applicant.monthly_incomes[idx] : applicant.monthly_income}/mo
              </Text>
              <Text style={{ ...styles.summaryTableMid, color: '#212121' }}>$0/mo</Text>
              <Text style={{ ...styles.summaryTableCol4, fontWeight: 600, color: '#4B5DB8' }}>
                ${applicant.monthly_incomes?.length ? applicant.monthly_incomes[idx] : applicant.monthly_income}/mo
              </Text>
            </View>
          </View>
        </>
      ) : (
        <></>
      )}
      <View style={styles.summaryTitle}>
        <Text style={styles.headingText}>Overall Income Summary</Text>
      </View>
      <View style={styles.summary}>
        <View style={styles.summaryRow}>
          <Text style={styles.textLight}>Income Method</Text>
          <Text style={styles.textDarkBold}>{getVerificationMethod(applicant.verification_method[idx])}</Text>
        </View>
        {applicant.verification_method[idx] === 'bank_income' ? (
          <>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Start Date of Income Gathered</Text>
              <Text style={styles.textDarkBold}>{moment(applicant.income_history[idx].start_date).format('MM/DD/YYYY')}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>End Date of Income Gathered</Text>
              <Text style={styles.textDarkBold}>{moment(applicant.income_history[idx].end_date).format('MM/DD/YYYY')}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Total Earnings</Text>
              <Text style={styles.textDarkBold}>${applicant.income_history[idx].total}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Total Income Sources</Text>
              <Text style={styles.textDarkBold}>{applicant.income_history[idx].incomeSources.length}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Total Income Category Types</Text>
              <Text style={styles.textDarkBold}>{applicant.income_history[idx].income_categories_count}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Total Income Transactions</Text>
              <Text style={styles.textDarkBold}>{applicant.income_history[idx].income_transactions_count}</Text>
            </View>
          </>
        ) : applicant.verification_method[idx] === 'paystub' || applicant.verification_method[idx] === 'payroll' ? (
          <>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Number of Paystubs {applicant.verification_method[idx] === 'payroll' ? 'Used' : 'Uploaded'}</Text>
              <Text style={styles.textDarkBold}>{applicant.income_history[idx].payrollCount}</Text>
            </View>
            {applicant.income_history[idx].payrollProvider ? (
              <View style={styles.summaryRow}>
                <Text style={styles.textLight}>Payroll Provider</Text>
                <Text style={styles.textDarkBold}>{applicant.income_history[idx].payrollProvider}</Text>
              </View>
            ) : (
              <></>
            )}
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Pay Frequency</Text>
              <Text style={styles.textDarkBold}>{applicant.income_history[idx].payFreq}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Employer Name</Text>
              <Text style={styles.textDarkBold}>{applicant.income_history[idx].employer}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Average Gross Pay</Text>
              <Text style={styles.textDarkBold}>${applicant.income_history[idx].avgGrossPay}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Average Deductions</Text>
              <Text style={styles.textDarkBold}>${applicant.income_history[idx].avgDeductions}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Average Net Pay</Text>
              <Text style={styles.textDarkBold}>${applicant.income_history[idx].avgNetPay}</Text>
            </View>
          </>
        ) : applicant.verification_method[idx] === 'w2' ? (
          <>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Number of documents uploaded</Text>
              <Text style={styles.textDarkBold}>{applicant.income_history[idx].w2sCounts}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Tax year</Text>
              <Text style={styles.textDarkBold}>{applicant.income_history[idx].taxYear}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Gross amount</Text>
              <Text style={styles.textDarkBold}>${applicant.income_history[idx].grossPay}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Average Earned Per Month</Text>
              <Text style={styles.textDarkBold}>${applicant.monthly_incomes?.length ? applicant.monthly_incomes[idx] : applicant.monthly_income}</Text>
            </View>
          </>
        ) : applicant.verification_method[idx] === '1099' ? (
          <>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Number of documents uploaded</Text>
              <Text style={styles.textDarkBold}>{applicant.income_history[idx].form1099sCount}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Tax year</Text>
              <Text style={styles.textDarkBold}>{applicant.income_history[idx].taxYear}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Gross amount</Text>
              <Text style={styles.textDarkBold}>${applicant.income_history[idx].grossPay}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Federal Income Tax Withheld</Text>
              <Text style={styles.textDarkBold}>${applicant.income_history[idx].taxWithheld}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.textLight}>Average Earned Per Month</Text>
              <Text style={styles.textDarkBold}>${applicant.monthly_incomes?.length ? applicant.monthly_incomes[idx] : applicant.monthly_incomee}</Text>
            </View>
          </>
        ) : (
          <></>
        )}
      </View>
      <Footer />
    </Page>
  );
};
