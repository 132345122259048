import { Box, Tab, Typography } from '@mui/material';
import { useState } from 'react';
import palette from '../../ui/palette';
import UserList from './user-list';
import PropertySelector from '../../components/property-selector';
import { Tabs } from '../../ui/tabs.ts';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/user/selector';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ManageUsers() {
  const [value, setValue] = useState(0);
  const currentUser = useSelector(getUser);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box component="header" sx={{ display: 'flex', alignItems: 'stretch', gap: '.75rem' }}>
        <Typography variant="h2">Manage Users</Typography>

        {currentUser.role === 'admin' ? (
          <></>
        ) : (
          <>
            <Box
              sx={{
                width: '1px',
                my: 2 / 8,
                backgroundColor: palette.dark.tertiary,
              }}
            ></Box>{' '}
            <PropertySelector />
          </>
        )}
      </Box>
      <Box sx={{ position: 'relative', mt: 6 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Users" {...a11yProps(0)} />
          <Tab label="Invitations" {...a11yProps(1)} />
        </Tabs>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: '2px',
            backgroundColor: palette.background.dark[20],
          }}
        ></Box>
      </Box>
      <UserList active={value === 0 ? 'yes' : 'no'} />
    </Box>
  );
}
