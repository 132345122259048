import EmailIcon from '@mui/icons-material/Email';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Chip, InputAdornment, MenuItem, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmptyTable from '../../../components/empty-table';
import ConfirmDialog from '../../../components/modals/confirm';
import AddCoSignerDialog from '../../../components/modals/reports/AddCoSignerDialog';
import CloseReportDialog from '../../../components/modals/reports/CloseReportDialog';
import FlagDialog from '../../../components/modals/reports/FlagDialog';
import ReopenReportDialog from '../../../components/modals/reports/ReopenReportDialog';
import { IReport, getVerificationMethod } from '../../../models/report';
import { notify } from '../../../store/ui/actions';
import { getSelectedProperty, getUser } from '../../../store/user/selector';
import { SearchBox } from '../../../ui/input';
import palette from '../../../ui/palette';
import { SortSelect } from '../../../ui/select';
import { ReportAction, ReportStatus, decisionLabel, issueStyles, recommendationStyles, reportColumns } from '../constants';
import ViewReportDialog from '../../../components/modals/reports/ViewReportDialog';
import { useNavigate } from 'react-router';
import { getApplicantsByStatus, sendApplicantInvite, updateApplication } from '../../../services/application.service';
import { formatApplication, getTruncateName } from '../util';

interface IReportList {
  page: number;
  pageSize: number;
  onUpdate: (data: { totalItems: number }) => void;
  reportTab: ReportStatus;
}

export default function ReportList({ page = 1, pageSize = 10, onUpdate, reportTab }: IReportList) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const [sortType, setSortType] = useState<'newest' | 'oldest' | 'dictionary' | null>('newest');

  // ------------------------------------------------
  const [search, setSearch] = useState<string>('');
  const [reports, setReports] = useState<IReport[]>([]);
  const selectedProperty = useSelector(getSelectedProperty);
  // ------------------------------------------------

  // New states
  const [selectedReport, setSelectedReport] = useState<any>(null);
  const [reportAction, setReportAction] = useState<string | null>(null);

  const getReports = useCallback(async () => {
    try {
      const { applicants, totalItems } = await getApplicantsByStatus(
        selectedProperty.code === 'all' ? '' : selectedProperty.code!!,
        page,
        pageSize,
        search,
        sortType || null,
        reportTab
      );
      const formattedApplicants = applicants.map((appicant: any) => formatApplication(appicant));
      onUpdate({ totalItems });
      setReports(formattedApplicants);
    } catch (err) {
      setReports([]);
    }
  }, [page, pageSize, reportTab, search, selectedProperty, sortType]);

  useEffect(() => {
    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportTab, selectedProperty, search, page, pageSize]);

  // Column Actions -------------------------------------------------------
  const handleViewReport = (uri: string) => {
    window.open(uri);
  };

  const handleDeleteApplicantion = async () => {
    if (selectedReport) {
      const response = await updateApplication({
        application_id: selectedReport.application_id,
        status: 'deleted',
      });
      if (response?.status === 'success') {
        setSelectedReport(null);
        dispatch(
          notify({
            severity: 'success',
            open: true,
            message: 'Report has been deleted',
          })
        );
        getReports();
      }
    }
  };

  const handleAddFlag = async (issue: string) => {
    if (selectedReport) {
      const response = await updateApplication({
        application_id: selectedReport.application_id,
        status: 'flagged',
        flag_issue: issue,
        flagged: true,
        flagged_by: user.name,
      });
      if (response?.status === 'success') {
        setSelectedReport(null);
        dispatch(
          notify({
            severity: 'success',
            open: true,
            message: 'The Application Has Been Flagged',
          })
        );
        getReports();
      }
      handleCloseReportActionDialog();
    }
  };

  const handleCloseApplication = async (reason: string) => {
    if (selectedReport) {
      const response = await updateApplication({
        application_id: selectedReport.application_id,
        status: 'closed',
        decision: reason,
        closed_by: user.name,
        closed_on: new Date(),
      });
      if (response?.status === 'success') {
        setSelectedReport(null);
        dispatch(
          notify({
            severity: 'success',
            open: true,
            message: 'Report Has Been Closed',
          })
        );
        getReports();
      }
      handleCloseReportActionDialog();
    }
  };

  const handleReopenApplication = async (reason: string) => {
    if (selectedReport) {
      const response = await updateApplication({
        application_id: selectedReport.application_id,
        status: 'active',
        decision: reason,
        closed_by: undefined,
        closed_on: undefined,
      });
      if (response?.status === 'success') {
        setSelectedReport(null);
        dispatch(
          notify({
            severity: 'success',
            open: true,
            message: 'Report Has Been Closed',
          })
        );
        getReports();
      }
      handleCloseReportActionDialog();
    }
  };

  const handleRemoveFlag = async (application_id: string) => {
    const response = await updateApplication({
      application_id,
      status: 'active',
      flagged: false,
    });
    if (response?.status === 'success') {
      setSelectedReport(null);
      dispatch(
        notify({
          severity: 'success',
          open: true,
          message: 'Flag Has Been Removed from Application',
        })
      );
      getReports();
    }
  };

  const handleViewApplication = (applicationId: string) => {
    navigate(`/applicant/${applicationId}`);
  };
  // ----------------------------------------------------------------------
  const handleCloseReportActionDialog = () => {
    setSelectedReport(null);
    setReportAction(null);
  };

  const renderActionDialogs = () => {
    if (!selectedReport) return null;

    if (reportAction === ReportAction.REMOVE) {
      return (
        <ConfirmDialog
          title="Are You Sure You Want to Remove The Application?"
          content="This action cannot be reversed."
          confirmText="Remove Application"
          onClose={handleCloseReportActionDialog}
          onConfirm={handleDeleteApplicantion}
        />
      );
    }

    if (reportAction === ReportAction.ADD_FLAG) {
      return <FlagDialog onSubmit={handleAddFlag} onClose={handleCloseReportActionDialog} />;
    }

    if (reportAction === ReportAction.CLOSE_REPORT) {
      return <CloseReportDialog onSubmit={handleCloseApplication} onClose={handleCloseReportActionDialog} />;
    }

    if (reportAction === ReportAction.ADD_CO_SIGNER) {
      return (
        <AddCoSignerDialog
          onSubmit={() => {
            dispatch(
              notify({
                severity: 'success',
                open: true,
                message: 'Co-signer Has Been Added',
              })
            );
            handleCloseReportActionDialog();
          }}
          onClose={handleCloseReportActionDialog}
        />
      );
    }

    if (reportAction === ReportAction.REOPEN_REPORT) {
      return <ReopenReportDialog onSubmit={handleReopenApplication} onClose={handleCloseReportActionDialog} />;
    }

    if (reportAction === ReportAction.VIEW_REPORT) {
      return <ViewReportDialog onClose={handleCloseReportActionDialog} application={selectedReport} />;
    }
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(() => {
    return reportColumns
      .filter(column => column.reportStatus.includes(reportTab))
      .map(column => {
        switch (column.header) {
          case 'Application ID':
            return {
              ...column,
              accessorFn: row => (
                <Typography onClick={() => handleViewApplication(row.application_id)} sx={{ cursor: 'pointer' }}>
                  {row.application_number}
                </Typography>
              ),
            };
          case 'Resend Invite':
            return {
              ...column,
              accessorFn: (row: any) => {
                return row.last_invite_sent && Math.abs(moment().diff(moment(row.last_invite_sent), 'hour')) <= 24 ? (
                  <Stack direction="row" alignItems="center" gap={1} color={palette.grey[40]}>
                    <EmailIcon />
                    <Typography sx={{ textDecoration: 'underline' }}>Invite Sent!</Typography>
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    color={palette.primary[30]}
                    sx={{ cursor: 'pointer' }}
                    onClick={async () => {
                      await sendApplicantInvite(row._id);
                      dispatch(
                        notify({
                          severity: 'success',
                          open: true,
                          message: 'Invitation is sent!',
                        })
                      );
                      getReports();
                    }}
                  >
                    <EmailOutlinedIcon />
                    <Typography sx={{ textDecoration: 'underline' }}>{row.last_invite_sent ? 'Resend Invite' : 'Send Invite'}</Typography>
                  </Stack>
                );
              },
            };
          case 'Applicant Name':
            return {
              ...column,
              accessorFn: (row: IReport) => (
                <Stack flexDirection="row" flexWrap="wrap" gap={1}>
                  {row.applicants
                    .filter((a: any) => a.role !== 'Guarantor' && a.role !== 'Non-Resident Co-sign' && a.role !== 'Family Member - Minor')
                    .map((app: any) => (
                      <Chip color="primary" label={getTruncateName(app.name)} />
                    ))}
                </Stack>
              ),
            };
          case 'Method':
            return {
              ...column,
              accessorFn: (row: any) => (
                <Stack flexDirection="row" flexWrap="wrap" gap={1}>
                  {row.verification_methods.map((method: string) => method && <Chip color="primary" label={getVerificationMethod(method)} />)}
                </Stack>
              ),
            };
          case 'Recommendation':
            return {
              ...column,
              accessorFn: (row: any) => {
                let rec = row.recommendation;
                if (
                  row.status === 'active' &&
                  rec === 'Fails Requirements' &&
                  row.income_level !== '-' &&
                  parseFloat(row.income_level.substring(0, row.income_level.indexOf('x'))) > 2.25 &&
                  row.verification_methods.indexOf('bank_income') >= 0
                ) {
                  rec = 'Needs Review';
                } else if (row.status === 'flagged') {
                  rec = 'Needs Review';
                }
                return rec ? (
                  <Chip
                    color="primary"
                    label={rec}
                    sx={{
                      ...(recommendationStyles[rec] ?? {}),
                    }}
                  />
                ) : (
                  <></>
                );
              },
            };
          case 'Issue':
            return {
              ...column,
              accessorFn: (row: any) =>
                row.flag_issue ? (
                  <Chip
                    label={row.flag_issue === 'System Error' ? 'Incorrect Document' : row.flag_issue}
                    sx={{
                      ...(issueStyles[row.flag_issue] ?? {}),
                    }}
                  />
                ) : (
                  <></>
                ),
            };
          case 'Decision':
            return {
              ...column,
              accessorFn: (row: any) =>
                row.decision ? (
                  <Chip
                    label={decisionLabel[row.decision]}
                    sx={{
                      color: '#52505E !important',
                      backgroundColor: '#F5F5F5 !important',
                    }}
                  />
                ) : (
                  <></>
                ),
            };
          default:
            return column;
        }
      });
  }, [reportTab]);

  const reportTable = useMaterialReactTable({
    columns,
    data: reports,
    enableColumnPinning: true,
    enableSorting: false,
    enableColumnActions: false,
    enableToolbarInternalActions: false,
    enablePagination: false,
    enableRowActions: true,
    positionActionsColumn: 'last',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '',
        size: 30,
      },
    },
    renderRowActionMenuItems: ({ row }) => {
      const report: IReport = row.original;
      switch (reportTab) {
        case ReportStatus.NOT_STARTED:
          return [
            <MenuItem key="view" onClick={() => handleViewApplication(report.application_id)}>
              View Application
            </MenuItem>,
            <MenuItem
              key="remove"
              onClick={() => {
                setSelectedReport(report);
                setReportAction(ReportAction.REMOVE);
              }}
            >
              Remove Application
            </MenuItem>,
          ];
        case ReportStatus.NEW_REPORTS:
          return [
            <MenuItem key="view-application" onClick={() => handleViewApplication(report.application_id)}>
              View Application
            </MenuItem>,
            <MenuItem
              key="view"
              onClick={() => {
                if (report.applicants.length) {
                  // Check if report has multiple applicants
                  setSelectedReport(report);
                  setReportAction(ReportAction.VIEW_REPORT);
                } else {
                  handleViewReport(report.report_link);
                }
              }}
            >
              View Report
            </MenuItem>,
            report.status !== 'flagged' ? (
              <MenuItem
                key="flag"
                onClick={() => {
                  setSelectedReport(report);
                  setReportAction(ReportAction.ADD_FLAG);
                }}
              >
                Flag
              </MenuItem>
            ) : (
              <MenuItem key="flag" onClick={() => handleRemoveFlag(report.application_id)}>
                Remove Flag
              </MenuItem>
            ),
            <MenuItem
              key="close"
              onClick={() => {
                setSelectedReport(report);
                setReportAction(ReportAction.CLOSE_REPORT);
              }}
            >
              Close Report
            </MenuItem>,
            <MenuItem
              key="remove"
              onClick={() => {
                setSelectedReport(report);
                setReportAction(ReportAction.REMOVE);
              }}
            >
              Remove Application
            </MenuItem>,
          ];
        case ReportStatus.CLOSED_REPORTS:
          return [
            <MenuItem key="view" onClick={() => handleViewApplication(report.application_id)}>
              View Application
            </MenuItem>,
            <MenuItem
              key="view2"
              onClick={() => {
                if (report.applicants.length) {
                  // Check if report has multiple applicants
                  setSelectedReport(report);
                  setReportAction(ReportAction.VIEW_REPORT);
                } else {
                  handleViewReport(report.report_link);
                }
              }}
            >
              View Report
            </MenuItem>,
            <MenuItem
              key="reprot"
              onClick={() => {
                setSelectedReport(report);
                setReportAction(ReportAction.REOPEN_REPORT);
              }}
            >
              Re-open Report
            </MenuItem>,
          ];
        default:
          return [];
      }
    },
    initialState: {
      columnPinning: { left: ['applicant_name'] },
      pagination: { pageSize: 10, pageIndex: 0 },
    },
    icons: {
      MoreHorizIcon: (props: any) => <MoreVertIcon {...props} />,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50, 100],
      showFirstButton: false,
      showLastButton: false,
    },
    muiTableHeadProps: {
      sx: {
        '& tr': {
          boxShadow: 'unset',
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: palette.primary[10],
        color: palette.grey[70],
        py: '.625rem',
        fontWeight: 500,
        borderBottom: 'unset',
        '&:first-child': {
          paddingLeft: '1.5rem',
          borderTopLeftRadius: '2rem',
          borderBottomLeftRadius: '2rem',
          '&:before': {
            backgroundColor: palette.primary[10],
            boxShadow: 'unset',
            paddingLeft: '1.5rem',
            borderTopLeftRadius: '2rem',
            borderBottomLeftRadius: '2rem',
          },
        },
        '&:last-child': {
          paddingRight: '1.5rem',
          borderTopRightRadius: '2rem',
          borderBottomRightRadius: '2rem',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        '&:first-child': {
          '&:before': {
            backgroundColor: 'transparent !important',
            boxShadow: 'unset',
          },
        },
      },
    },
    muiTableBodyRowProps: ({ row }: any) => ({
      onClick: () => {
        console.log(row);
        handleViewApplication(row.original.application_id);
      },
      sx: {
        '&:hover td::after': {
          backgroundColor: '#FAFAFA',
        },
        cursor: 'pointer',
      },
    }),
    muiTablePaperProps: {
      sx: {
        boxShadow: 'unset',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        display: 'none',
      },
    },
    muiTopToolbarProps: {
      sx: {
        display: 'none',
      },
    },
  });

  return (
    <>
      <Box sx={{ mt: 4, position: 'relative' }}>
        <Stack direction="row" gap={3}>
          <SearchBox
            sx={{ width: '22.5rem' }}
            placeholder="Search"
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      width: '2rem',
                      height: '1.5rem',
                      color: palette.dark.tertiary,
                      borderRadius: '.5rem',
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <SortSelect
            value={sortType}
            sx={{ width: '12.5rem' }}
            onChange={(event: SelectChangeEvent) => {
              setSortType(event.target.value as 'newest' | 'oldest' | 'dictionary' | null);
            }}
          >
            <MenuItem value="newest">Newest</MenuItem>
            <MenuItem value="oldest">Oldest</MenuItem>
            <MenuItem value="dictionary">Name (A-Z)</MenuItem>
          </SortSelect>
        </Stack>

        {reports.length > 0 ? (
          <Box sx={{ mt: 4, border: 'none', boxShadow: 'none' }}>
            <MaterialReactTable table={reportTable} />
          </Box>
        ) : (
          <EmptyTable />
        )}
      </Box>

      {/* Action Dialogs */}
      {renderActionDialogs()}
    </>
  );
}
