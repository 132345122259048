import { SearchBox } from '../../../ui/input';
import { Stack, InputAdornment, Card, Typography, List } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { TableBody, TableCell, TableHead, TableRow } from '../../../ui/table';
import MoreVertRounded from '@mui/icons-material/MoreVertRounded';
import { Button, ButtonCover } from '../../../ui/button';
import palette from '../../../ui/palette';
import { Popover, PopoverActionListItem } from '../../../ui/popover';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import { useCallback, useEffect, useState } from 'react';
import { Property } from '../../../models/property';
import AddEditProperty from '../add-edit-property';
import { deleteProperty, getAllProperties } from '../../../services/property.service';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../store/ui/actions';
import ConfirmDialog from '../../../components/modals/confirm';
import EmptyTable from '../../../components/empty-table';
import TablePagination, { PageType } from '../../../components/pagination';

enum PopupType {
  EditProperty = 'EditProperty',
  AddProperty = 'AddProperty',
  RemoveProperty = 'RemoveProperty',
}

interface IPropertyActionProps {
  onEdit: () => void;
  onRemove: () => void;
}

function PropertyAction({ onEdit, onRemove }: IPropertyActionProps) {
  return (
    <List sx={{ p: 6 / 8, minWidth: '160px' }}>
      <PopoverActionListItem onClick={onEdit}>
        <Typography>Edit Property</Typography>
      </PopoverActionListItem>
      <PopoverActionListItem onClick={onRemove}>
        <Typography>Remove Property</Typography>
      </PopoverActionListItem>
    </List>
  );
}

export default function PropertyList() {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState<PageType>({
    page: 1,
    rowsPerPage: 25,
  });
  const [popover, setPopover] = useState<{
    anchorEl: HTMLButtonElement | null;
    id: string | null;
  }>({
    anchorEl: null,
    id: null,
  });
  const [property, setProperty] = useState<Property | null>(null);
  const [searchText, setSearchText] = useState('');
  const [properties, setProperties] = useState<Array<Property>>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [popup, setPopup] = useState<{
    isOpen: boolean;
    type: PopupType | null;
    property: Property | null;
  }>({
    isOpen: false,
    type: null,
    property: null,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setPopover({ anchorEl: event.currentTarget, id });
  };

  const handleClose = () => {
    setPopover({
      anchorEl: null,
      id: null,
    });
  };

  const open = Boolean(popover?.anchorEl);

  const getProperties = async () => {
    dispatch(showLoader());
    try {
      const { properties, totalItems } = await getAllProperties(pagination.page, pagination.rowsPerPage, searchText);
      setProperties(properties || []);
      setTotalItems(totalItems || 0);
    } catch (err) {
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    getProperties();
  }, [pagination, searchText]);

  const onSearch = (value: string) => {
    setSearchText(value || '');
  };

  const renderUserActionDialogs = useCallback(() => {
    switch (popup.type) {
      case PopupType.EditProperty:
        return <AddEditProperty isEditMode={true} open={popup.isOpen} property={popup.property} handleClose={refresh => handlePopupClose(refresh)} />;
      case PopupType.AddProperty:
        return <AddEditProperty open={popup.isOpen} property={popup.property} handleClose={refresh => handlePopupClose(refresh)} />;
      case PopupType.RemoveProperty:
        return (
          <ConfirmDialog
            title="Are You Sure You Want to Remove The Property?"
            content="This action cannot be reversed."
            confirmText="Remove Property"
            onClose={() => handlePopupClose(true)}
            onConfirm={() => {
              if (popup.property) {
                removeProperty(popup.property._id);
              }
            }}
          />
        );
    }
  }, [popup]);

  const handlePopupClose = (refresh?: boolean) => {
    if (refresh) {
      getProperties();
    }
    setPopup({
      ...popup,
      isOpen: false,
      type: null,
    });
  };

  const removeProperty = async (propertyId: string) => {
    dispatch(showLoader());
    try {
      const res = await deleteProperty(propertyId);
      await getProperties();
    } catch (err) {
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          border: '1px solid #D4D7DC',
          borderRadius: '1.5rem',
          pt: 4,
          px: 3,
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <SearchBox
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSearch(event.target.value)}
            sx={{ width: '22.5rem' }}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      width: '2rem',
                      height: '1.5rem',
                      color: '#27293759',
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              setPopup({
                type: PopupType.AddProperty,
                isOpen: true,
                property: null,
              });
            }}
          >
            <ControlPointRoundedIcon sx={{ mr: 10 / 8 }} />
            Add Property
          </Button>
        </Stack>
        {properties?.length ? (
          <TableContainer component={Paper} sx={{ mt: 5, border: 'none', boxShadow: 'none' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Property Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>City/State</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell sx={{ width: '56px' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(properties || []).map((property, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>
                        <Typography variant="body1">{property.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{property.street}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {property.city}, {property.state}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{property.active ? 'Active' : 'Inactive'}</Typography>
                      </TableCell>
                      <TableCell sx={{ width: '56px' }}>
                        <ButtonCover
                          onClick={e => {
                            handleClick(e, 'action-popover');
                            setProperty(property);
                          }}
                          sx={{
                            p: 1,
                            borderRadius: '.5rem',
                            '&:hover': {
                              backgroundColor: palette.primary[10],
                            },
                          }}
                        >
                          <MoreVertRounded sx={{ color: palette.grey[40] }} />
                        </ButtonCover>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <EmptyTable />
        )}
      </Card>
      <TablePagination pagination={pagination} setPagination={setPagination} totalItems={totalItems} />
      <Popover
        id={popover?.id as string}
        open={open && popover?.id === 'action-popover'}
        anchorEl={popover?.anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PropertyAction
          onEdit={() =>
            setPopup({
              type: PopupType.EditProperty,
              property,
              isOpen: true,
            })
          }
          onRemove={() => {
            setPopup({
              type: PopupType.RemoveProperty,
              property,
              isOpen: true,
            });
          }}
        />
      </Popover>
      {renderUserActionDialogs()}
    </>
  );
}
