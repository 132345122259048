import { Stack, Typography } from '@mui/material';
import { VerificationMethodProps } from './BankIncomeMode';
import DefaultVertificationMode from './DefaultVertificationMode';

export default function W2Mode({ selectedApplicant, application, verificationMethodIndex }: VerificationMethodProps) {
  const selectedIncomeHistory = selectedApplicant.income_history[verificationMethodIndex] ?? selectedApplicant.income_history[0];

  return (
    <>
      <DefaultVertificationMode selectedApplicant={selectedApplicant} application={application} verificationMethodIndex={verificationMethodIndex} />
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Number of W2s submitted</Typography>
        <Typography variant="body1">{selectedIncomeHistory.w2sCounts}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Number of Clean Docs</Typography>
        <Typography variant="body1">{selectedIncomeHistory.w2sCounts - (selectedIncomeHistory.payStubErrorCount ?? 0)}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Number of Error Docs</Typography>
        <Typography variant="body1">{selectedIncomeHistory.payStubErrorCount}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Employer</Typography>
        <Typography variant="body1">{selectedIncomeHistory.employer}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Gross Pay</Typography>
        <Typography variant="body1">${selectedIncomeHistory.grossPay}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Tax Yeary</Typography>
        <Typography variant="body1">{selectedIncomeHistory.taxYear}</Typography>
      </Stack>
    </>
  );
}
