import axios, { AxiosHeaders } from 'axios';
import { BASE_URL } from '../constants/constants';
import { logoutUser } from './auth.service';

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      logoutUser();
    }
    return Promise.reject(error);
  }
);

if (localStorage.getItem('auth_token') !== null) {
  instance.defaults.headers.common['x-access-token'] = localStorage.getItem('auth_token') as string;
}

export function setAuthToken(token: string) {
  instance.defaults.headers.common['x-access-token'] = token;
  localStorage.setItem('auth_token', token);
}

export function getRequest(url: string, payload?: any) {
  return instance({
    method: 'get',
    url,
  }).then(res => res.data);
}

export function postRequest(url: string, payload?: any, headers?: AxiosHeaders) {
  return instance({
    method: 'post',
    url,
    ...(payload ? { data: payload } : {}),
    ...(headers ? { headers } : {}),
  }).then(res => res.data);
}

export function patchRequest(url: string, payload: any) {
  return instance({
    method: 'patch',
    url,
    data: payload,
  });
}

export function putRequest(url: string, payload: any) {
  return instance({
    method: 'put',
    url,
    data: payload,
  }).then(res => res.data);
}

export function deleteRequest(url: string, payload?: any) {
  return instance({
    method: 'delete',
    url,
    ...(payload ? { data: payload } : {}),
  }).then(res => res.data);
}

export const hasToken = () => {
  return !!instance.defaults.headers.common['x-access-token'];
};
