import { deleteRequest, getRequest, postRequest, putRequest } from './http.service';

const baseUrl = '/property';

export const addProperty = (property: any) => {
  return postRequest(`${baseUrl}`, property);
};

export const getPropertyById = (propertyId: string) => {
  return getRequest(`${baseUrl}/${propertyId}`);
};

export const getMyProperties = () => {
  return getRequest(`${baseUrl}/my`).then(res => res.data);
};

export const getAllProperties = (page?: number, pageSize?: number, search = '') => {
  let queryParams = '';
  if (page && pageSize) {
    queryParams = `page=${page}&pageSize=${pageSize}`;
  }
  if (search) {
    queryParams += `&search=${search}`;
  }
  if (queryParams) {
    queryParams = `?${queryParams}`;
  }
  return getRequest(`${baseUrl + queryParams}`).then(res => res.data);
};

export const updateProperty = (property: any) => {
  return putRequest(`${baseUrl}/${property._id}`, property);
};

export const deleteProperty = (propertyId: string) => {
  return deleteRequest(`${baseUrl}/${propertyId}`);
};

export const getUnits = (propertyId: string) => {
  return getRequest(`${baseUrl}/${propertyId}/units`);
};
