import {
  Box,
  Chip,
  InputBase,
  Select as MuiSelect,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import palette from "../palette";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ButtonCover } from "../button";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    padding: ".5rem .75rem",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input": {
    border: "1px solid",
    borderColor: palette.grey[50],
    borderRadius: ".5rem",
  },
}));

export const Select = ({ children, ...props }: any) => {
  return (
    <MuiSelect input={<BootstrapInput placeholder="dshj" />} {...props}>
      {children}
    </MuiSelect>
  );
};

export const SelectChip = ({ deleteBtn, onDelete, label, ...props }: any) => {
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      alignItems="center"
      {...props}
      sx={{
        background: palette.background.dark[20],
        borderRadius: "0.375rem",
        p: 6 / 8,
        pl: 12 / 8,
      }}
    >
      <Typography variant="subtitle2">{label}</Typography>
      <ButtonCover
        onMouseDown={(event: any) => {
          event.stopPropagation();
        }}
        onClick={onDelete}
      >
        <CloseRoundedIcon
          onClick={onDelete}
          sx={{
            ml: 6 / 8,
            color: "#27293759",
            height: "1.25rem",
            width: "1.25rem",
          }}
        />
      </ButtonCover>
    </Stack>
  );
};

const PropertySelectInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    padding: ".5rem .75rem",
  },
  "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input": {
    borderRadius: ".5rem",
  },
  "& .MuiSvgIcon-root": {
    height: "2rem",
    width: "2rem",
    marginLeft: ".5rem",
  },
  "& .MuiSelect-select.MuiInputBase-input": {
    padding: ".25rem 2.5rem .25rem 0 !important",
  },
}));

export const PropertySelect = ({ children, ...props }: any) => {
  return (
    <MuiSelect
      input={<PropertySelectInput />}
      {...props}
      IconComponent={ExpandMoreRoundedIcon}
    >
      {children}
    </MuiSelect>
  );
};

const FilterInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: '1.5rem',
    padding: ".6781rem .75rem",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input": {
    border: "1px solid",
    borderColor: palette.grey[50],
    borderRadius: "1.5rem",
  },
}));

export const SortSelect = ({ children, ...props }: any) => {
  return (
    <MuiSelect
      input={<FilterInput />}
      {...props}
      IconComponent={ExpandMoreRoundedIcon}
    >
      {children}
    </MuiSelect>
  );
};
