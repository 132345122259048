import { getRequest, postRequest, putRequest } from './http.service';

const baseUrl = '/application';

export const getApplicantsByStatus = (
  property_code: string,
  page?: number | null,
  pageSize?: number | null,
  search: string | null = '',
  sortType?: 'newest' | 'oldest' | 'dictionary' | null,
  status?: string | null
) => {
  let queryParams = '';
  if (page && pageSize) {
    queryParams = `page=${page}&pageSize=${pageSize}`;
  }
  if (search) {
    queryParams += `&search=${search}`;
  }
  if (sortType) {
    queryParams += `&sortType=${sortType}`;
  }
  if (property_code) {
    queryParams += `&property_code=${property_code}`;
  }
  if (status) {
    queryParams += `&status=${status}`;
  }
  if (queryParams) {
    queryParams = `?${queryParams}`;
  }
  return getRequest(`${baseUrl}${queryParams}`).then(r => r.data);
};

export const sendApplicantInvite = (application_id: string, applicant_id: string = '') => {
  return postRequest(`${baseUrl}/send-invite`, { application_id, applicant_id });
};

export const getApplicationById = (application_id: string) => {
  return getRequest(`${baseUrl}/${application_id}`);
};

export const updateApplication = (application: any) => {
  return putRequest(`${baseUrl}`, application);
};

export const getApplicationSummary = (property?: string) => {
  let queryParams = '';
  if (property) {
    queryParams += `?property_code=${property}`;
  }
  return getRequest(`${baseUrl}/summary${queryParams}`);
};

export const updateApplicant = async (applicantId: string, update: any) => {
  return putRequest(`${baseUrl}/applicant/${applicantId}`, update);
};

export const createTransferApplication = async (data: any) => {
  return postRequest(`${baseUrl}`, data);
};
