import { NotificationProps } from "../../models/ui";
import { ActionTypes } from "./action-types";
import { IHideLoader, INotify, IShowLoader } from "./types";

export const showLoader = (): IShowLoader => ({
  type: ActionTypes.ShowLoader,
});

export const hideLoader = (): IHideLoader => ({
  type: ActionTypes.HideLoader,
});

export const notify = (props: Partial<NotificationProps>): INotify => ({
  type: ActionTypes.Notify,
  payload: {
    autoHideDuration: 3000,
    ...props,
  },
});
