import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';

export const IOSSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: 38,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 4,
    marginLeft: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
        background: '#27293759',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 1,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="10" width="10" viewBox="0 0 10 10"><path fill="${encodeURIComponent(
          '#646464'
        )}" d="M6.49935 3.54167H6.08268V2.70833C6.08268 1.55833 5.14935 0.625 3.99935 0.625C2.84935 0.625 1.91602 1.55833 1.91602 2.70833V3.54167H1.49935C1.04102 3.54167 0.666016 3.91667 0.666016 4.375V8.54167C0.666016 9 1.04102 9.375 1.49935 9.375H6.49935C6.95768 9.375 7.33268 9 7.33268 8.54167V4.375C7.33268 3.91667 6.95768 3.54167 6.49935 3.54167ZM3.99935 7.29167C3.54102 7.29167 3.16602 6.91667 3.16602 6.45833C3.16602 6 3.54102 5.625 3.99935 5.625C4.45768 5.625 4.83268 6 4.83268 6.45833C4.83268 6.91667 4.45768 7.29167 3.99935 7.29167ZM2.74935 3.54167V2.70833C2.74935 2.01667 3.30768 1.45833 3.99935 1.45833C4.69102 1.45833 5.24935 2.01667 5.24935 2.70833V3.54167H2.74935Z"/></svg>')`,
      },
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
