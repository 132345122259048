import { MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setProperties, setSelectedProperty } from '../../store/user/actions';
import { getSelectedProperty, getUser } from '../../store/user/selector';
import { PropertySelect } from '../../ui/select';
import { useEffect, useState } from 'react';
import { getMyProperties } from '../../services/property.service';

export default function PropertySelector() {
  const [propertyList, setPropertyList] = useState<any[]>([]);
  const selectedProperty = useSelector(getSelectedProperty);
  const currentUser = useSelector(getUser);
  const dispatch = useDispatch();

  useEffect(() => {
    getMyProperties().then(res => {
      let pList = [...res];
      // if (currentUser?.role === 'admin') {
      //   pList = [{ _id: 'all', name: 'All Properties', code: 'all' }, ...res];
      // } else {
      //   pList = [...res];
      // }
      setPropertyList(pList);
      dispatch(setProperties(pList));
      if (pList.length > 0 && (!selectedProperty || !selectedProperty._id)) {
        dispatch(
          setSelectedProperty({
            _id: pList[0]?._id,
            name: pList[0]?.name,
            code: pList[0]?.code,
          })
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {selectedProperty && (
        <PropertySelect
          value={selectedProperty?._id}
          onChange={(event: SelectChangeEvent) => {
            dispatch(setSelectedProperty(propertyList.find(p => p._id === (event.target.value as string)) || null));
          }}
          renderValue={(value: any) => <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, lineHeight: 32 / 24 }}>{selectedProperty?.name}</Typography>}
        >
          {propertyList.map(p => {
            return (
              <MenuItem value={p._id} key={p._id}>
                {p.name}
              </MenuItem>
            );
          })}
        </PropertySelect>
      )}
    </>
  );
}
