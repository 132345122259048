import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from '../components/layout';
import Login from '../pages/auth/login';
import Faq from '../pages/faq';
import ManageProperties from '../pages/manage-properties';
import ManageUsers from '../pages/manage-users';
import Reports from '../pages/Reports';
import Guard from './guard';
import { RouteUrl } from './types';
import ApplicantDetail from '../pages/applicant-detail';

interface AppRoutesProp {
  isAuthenticated: boolean;
}

export default function AppRoutes(props: AppRoutesProp): JSX.Element {
  const { isAuthenticated } = props;

  return (
    <Routes>
      <Route element={<Guard isRouteAccessible={!isAuthenticated} redirectRoute={RouteUrl.Home} />}>
        <Route path={RouteUrl.Login} element={<Login />} />
      </Route>
      <Route element={<Guard isRouteAccessible={isAuthenticated} redirectRoute={RouteUrl.Login} />}>
        <Route path="/" element={<Navigate to={RouteUrl.Reports} />} />
        <Route
          path={RouteUrl.Reports}
          element={
            <Layout>
              <Reports />
            </Layout>
          }
        />
        <Route
          path={RouteUrl.ManageUsers}
          element={
            <Layout>
              <ManageUsers />
            </Layout>
          }
        />
        <Route
          path={RouteUrl.ManageProperties}
          element={
            <Layout>
              <ManageProperties />
            </Layout>
          }
        />
        <Route
          path={RouteUrl.Faq}
          element={
            <Layout>
              <Faq />
            </Layout>
          }
        />
        <Route
          path={RouteUrl.ApplicantDetail}
          element={
            <Layout>
              <ApplicantDetail />
            </Layout>
          }
        />
      </Route>
    </Routes>
  );
}
