import { ListItem, Popover as MuiPopover, styled } from "@mui/material";
import palette from "../palette";

export const Popover = styled(MuiPopover)(() => ({
  "& .MuiPaper-root.MuiPopover-paper": {
    borderRadius: ".5rem",
    border: "1px solid",
    borderColor: palette.grey[50],
    boxShadow: "0px 6px 24px 0px rgba(19, 29, 83, 0.10)",
  },
}));

export const PopoverActionListItem = styled(ListItem)(() => ({
  padding: ".625rem .875rem .625rem .5rem",
  cursor: "pointer",
  "& svg": {
    color: palette.grey[40],
    height: "1.5rem",
    width: "1.5rem",
    marginRight: ".625rem",
  },
  "&:hover": {
    background: palette.background.dark[20],
    color: palette.primary[30],
    fontWeight: 500,
  },
}));